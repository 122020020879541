/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import {
  DateRangePicker,
  FocusedInputShape,
  AnchorDirectionShape,
} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment, { Moment } from 'moment'
import debounce from 'lodash.debounce'
import { addDays } from '../utils/date-utils'

interface Props {
  initialstartDate: Date
  initialendDate: Date
  handleStartDateChange?: (date: Date) => void
  handleEndDateChange?: (date: Date) => void
  handleDatesChange?: ([startDate, endDate]: [Date, Date]) => void
  initialDateDisplayed?: boolean
  isMonthly?: boolean
  direction?: AnchorDirectionShape
  marge?: number
  shortDisplayFormat?: boolean
  resetDate?: boolean
  isOutsideRange?: ((day: moment.Moment) => boolean) | undefined
}

const DatePicker: React.FC<Props> = ({
  initialstartDate,
  initialendDate,
  handleStartDateChange = () => {},
  handleEndDateChange = () => {},
  handleDatesChange = () => {},
  initialDateDisplayed,
  isMonthly,
  direction,
  marge,
  shortDisplayFormat,
  resetDate,
  isOutsideRange,
}: Props) => {
  const [enddate, setEndDate] = useState<Moment | null>(moment(initialendDate))
  const [startdate, setStartDate] = useState<Moment | null>(
    initialstartDate
      ? moment(initialstartDate)
      : moment(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1))
  )
  const [focusedinput, setfocusedInput] = useState<FocusedInputShape | null>()
  const [canDisplay, setCanDisplay] = useState(initialDateDisplayed)

  const setStartDateBouncer = debounce(handleStartDateChange, 1000)
  const setEndDateBouncer = debounce(handleEndDateChange, 1000)
  const setBothDatesBouncer = debounce(handleDatesChange, 1000)

  const orientation = window.matchMedia('(max-width: 34.3rem)').matches
    ? 'vertical'
    : 'horizontal'

  useEffect(() => {
    if (resetDate) {
      setStartDate(null)
      setEndDate(null)
    }
  }, [resetDate])
  return (
    <DateRangePicker
      displayFormat={shortDisplayFormat ? 'DD/MM/YY' : 'DD/MM/YYYY'}
      startDate={canDisplay ? startdate : null} // momentPropTypes.momentObj or null,
      startDatePlaceholderText={shortDisplayFormat ? 'Début' : 'Date début'}
      startDateId="startdate_Id" // PropTypes.string.isRequired,
      endDate={canDisplay ? enddate : null} // momentPropTypes.momentObj or null,
      endDateId="enddate_Id" // PropTypes.string.isRequired,
      endDatePlaceholderText={shortDisplayFormat ? 'Fin' : 'Date fin'}
      anchorDirection={direction}
      onDatesChange={({ startDate, endDate }) => {
        setCanDisplay(true)
        if (startDate) {
          let dateEnd: Date = new Date()
          let dateStart: Date = new Date()
          if (isMonthly) {
            dateEnd = enddate?.toDate() ?? initialendDate
            dateStart = startDate.toDate()
          } else {
            dateEnd = addDays(enddate ? enddate.toDate() : initialendDate, 1)
            dateStart = addDays(startDate.toDate(), marge || 0)
          }
          setStartDate(startDate)
          setStartDateBouncer(dateStart)
          setBothDatesBouncer([dateStart, dateEnd])
        }
        if (endDate) {
          let dateEnd: Date = new Date()
          let dateStart: Date = new Date()
          if (isMonthly) {
            dateEnd = endDate.toDate()
            dateStart = startdate?.toDate() ?? initialstartDate
          } else {
            dateEnd = addDays(endDate.toDate(), 1)
            dateStart = addDays(
              startdate ? startdate.toDate() : initialstartDate,
              marge || 0
            )
          }
          setEndDate(endDate)
          setEndDateBouncer(dateEnd)
          setBothDatesBouncer([dateStart, dateEnd])
        }
        if (startDate && endDate) {
          let dateEnd: Date = new Date()
          let dateStart: Date = new Date()
          if (isMonthly) {
            dateEnd = endDate.toDate()
            dateStart = startDate.toDate()
          } else {
            dateEnd = addDays(endDate.toDate(), 1)
            dateStart = addDays(startDate.toDate(), marge || 0)
          }
          setBothDatesBouncer([dateStart, dateEnd])
        }
      }} // PropTypes.func.isRequired,
      focusedInput={focusedinput || null} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={(focusedInput) => setfocusedInput(focusedInput)} // PropTypes.func.isRequired,
      isOutsideRange={isOutsideRange}
      // style
      hideKeyboardShortcutsPanel
      initialVisibleMonth={() => {
        if (focusedinput === 'endDate') {
          return enddate || moment()
        }
        return startdate || moment()
      }}
      orientation={orientation}
      verticalHeight={575}
      customArrowIcon="-"
    />
  )
}
DatePicker.defaultProps = {
  initialDateDisplayed: true,
  isMonthly: false,
  direction: 'left',
  marge: 0,
  shortDisplayFormat: false,
  resetDate: false,
  isOutsideRange: (day: moment.Moment) => false,
}
export default DatePicker
