import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Alert from './Alert'
import CreateDemandExpirationBody from '../layouts/MyDemands/CreateDemandExpirationBody'
import Loading from './Loading'

interface Props {
  showModal: boolean
  handleCloseSessionModal: () => void
  endTime: Date
  isLoading: boolean
}

const SessionExpirationModal: React.FC<Props> = ({
  showModal,
  handleCloseSessionModal,
  endTime,
  isLoading,
}: Props) => {
  const { t } = useTranslation()
  const remainingTime = (startTime: Date) =>
    new Date(endTime.getTime() - startTime.getTime())

  const [expirationTime, setExpirationTime] = useState<Date>(
    remainingTime(new Date())
  )
  const [mainText, setMainText] = useState<string>('')
  const timerRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined)

  useEffect(() => {
    if (timerRef?.current) {
      clearInterval(timerRef?.current)
    }

    const timerId = setInterval(() => {
      setExpirationTime(remainingTime(new Date()))
    }, 1000)

    timerRef.current = timerId

    return () => {
      clearInterval(timerRef.current)
    }
  }, [])

  useEffect(() => {
    setMainText(
      window.location.pathname.includes('create-demand')
        ? 'demandBody'
        : 'body1'
    )
  }, [])

  return (
    <Modal
      show={showModal}
      className="sessionExpiration__modal"
      onHide={() => {
        setExpirationTime(endTime)
      }}
      size="lg"
    >
      <Modal.Header>
        <Alert type="warning" alertMessaeg={t('session.modal.title')} />
      </Modal.Header>
      <Modal.Body>
        <div className="sessionExpiration__content">
          {mainText === 'demandBody' ? (
            <CreateDemandExpirationBody />
          ) : (
            <div>{t('session.modal.body1')}</div>
          )}
          <div>
            <span>{t('session.modal.body2')}</span>
            <span>
              {`${expirationTime.getMinutes()}:${String(
                expirationTime.getSeconds()
              ).padStart(2, '0')}`}
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="sessionExpiration__actions">
          <button
            className="generic-button form__submit"
            type="submit"
            disabled={isLoading}
            onClick={() => {
              handleCloseSessionModal()
            }}
          >
            <Loading isLoading={isLoading} inButton>
              {t('session.modal.button')}
            </Loading>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default SessionExpirationModal
