/* eslint-disable @typescript-eslint/no-explicit-any */
import { userAdminLandingPage } from '../services/roles-services'
import ROUTES from './ROUTES'

export default async function handleNavigation(
  roleNames: string[],
  history: any
) {
  // tarnslate roles to numbers
  const userManager = userAdminLandingPage(roleNames)
  const redirectionPath = localStorage.getItem('redirectTo')
  if (redirectionPath) {
    history.push(redirectionPath)
    localStorage.removeItem('redirectTo')
  } else if (userManager) {
    history.push(ROUTES.UsersManagement)
  } else {
    history.push(ROUTES.Dashboard)
  }
}
