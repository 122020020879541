/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactNode } from 'react'
import SearchResultItem from '../../../utils/SearchResultItem'
import SvgIcon from '../../SvgIcon'
import StyledTags from '../../atom/BandeauPerimetre/Item'
import StyledTagsWrapper from '../../atom/BandeauPerimetre/ItemWrapper'

interface Props {
  placeholder?: ReactNode
  items: SearchResultItem[] | undefined
  entityAbbreviation: string
  onUnselect: (id: string) => void
}
const SelectedItems: React.FC<Props> = ({
  placeholder,
  items,
  entityAbbreviation,
  onUnselect,
}: Props) => {
  if (!items || items.length === 0) {
    return <></>
  }

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
  }
  return (
    <>
      <div className="search-selected__placeholder">{placeholder}</div>
      <StyledTagsWrapper withOverflow={items.length >= 3}>
        {items?.map(({ id, nbSubEntity, line1 }) => {
          return (
            <StyledTags key={id} onClick={stopPropagation}>
              <div className="search-selected__item-name">{line1}</div>
              <div className="search-selected__sub-entity">
                <div>
                  {nbSubEntity} {entityAbbreviation}
                </div>
                <div
                  className="search-selected__close"
                  onClick={() => onUnselect(id)}
                >
                  <SvgIcon
                    name="close"
                    className="search-selected__close-icon"
                  />
                </div>
              </div>
            </StyledTags>
          )
        })}
      </StyledTagsWrapper>
    </>
  )
}
SelectedItems.defaultProps = { placeholder: '' }

export default SelectedItems
