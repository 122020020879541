import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Cookies from 'universal-cookie'

import PropTypes from 'prop-types'
import ROUTES from '../utils/ROUTES'

import handleNavigation from '../utils/RedirectionUtils'

import { AppContext } from '../store/context'

const IsEligibleToAccessNoAuthPages: React.FC = ({ children }) => {
  const history = useHistory()
  const location = useLocation()

  const { state } = React.useContext(AppContext)

  const cookies = new Cookies()

  React.useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token')
    const roles = cookies.get('current_user_roles')

    // The user is probably still connected. Let AutoRefreshToken do his job.
    if (refreshToken && roles) {
      handleNavigation(roles, history)
    } else if (
      state.user.email === '' &&
      !refreshToken &&
      location.pathname === ROUTES.Login
    ) {
      history.push(ROUTES.CheckEmail)
    }
  }, [state.user])

  return <> {children} </>
}

IsEligibleToAccessNoAuthPages.propTypes = {
  children: PropTypes.node.isRequired,
}

export default IsEligibleToAccessNoAuthPages
