/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Collapse } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'
import Button from './Button'
import SvgIcon from './SvgIcon'

interface Props {
  handleDecision: (d: boolean) => void
}
declare global {
  interface Window {
    gtag: any
  }
}
const CookiesBanner: React.FC<Props> = ({ handleDecision }: Props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const url = `${process.env.REACT_APP_FOOTER_URL_PDF}resources/`
  const cookies = new Cookies()

  // clear the old cookies
  useEffect(() => {
    document.cookie.split(';').forEach((c) => {
      if (c.trim().startsWith('_ga')) {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(
            /=.*/,
            `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.suez.com`
          )
      }
    })
  })

  // cookies consent management
  function allowCookies() {
    cookies.set('analytics_storage', 'granted', {
      path: '/',
      secure: true,
    })
    window.dataLayer.push({
      event: 'sz_conversion',
      sz_conversion_type: 'Autoriser tous les cookies',
    })
    window.gtag('consent', 'update', {
      analytics_storage: 'granted',
    })
    handleDecision(false)
  }

  function notAllowCookies() {
    cookies.set('analytics_storage', 'denied', {
      path: '/',
      secure: true,
    })
    window.dataLayer.push({
      event: 'sz_conversion',
      sz_conversion_type: 'N’autoriser que les cookies indispensables',
    })
    window.gtag('consent', 'update', {
      analytics_storage: 'denied',
    })
    handleDecision(false)
  }

  return (
    <div className="cookies-banner__modal">
      <div className="cookies-banner__container">
        <div className="cookies-banner__content">
          <div className="cookies-banner__content-top">
            <p>{t('cookiesBanner.generalInfo')}</p>
          </div>
          <div className="cookies-banner__content-bottom">
            <Button
              className="cookies-banner__button"
              onClick={() => allowCookies()}
            >
              {t('cookiesBanner.accept')}
            </Button>
            <Button
              className="cookies-banner__button"
              onClick={() => notAllowCookies()}
            >
              {t('cookiesBanner.deny')}
            </Button>
            <Button
              className="cookies-banner__button"
              onClick={() => setOpen(true)}
            >
              {t('cookiesBanner.readMore')}
            </Button>
          </div>
        </div>
        <div className="cookies-banner__infos">
          <Collapse in={open}>
            <div id="row example-collapse-text">
              <div className="cookies-banner__closeButton-container">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="cookies-banner__closeButton"
                >
                  <SvgIcon
                    name="close"
                    className="cookies-banner__close-icon"
                  />
                </button>
              </div>
              <div className="col-md-8 mx-auto cookies-banner__contentText">
                <p>{t('cookiesBanner.parag1')}</p>
                <br />
                <p>{t('cookiesBanner.parag2')}</p>
                <br />
                <p>
                  {t('cookiesBanner.parag3')}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_FOOTER_URL_PDF}resources/ECE-Charte_de_gestion_des_cookies-SUEZ_RV.pdf`}
                  >
                    {t('cookiesBanner.here')}
                  </a>
                  . {t('cookiesBanner.parag3Next')}
                </p>
                <br />
                <p>{t('cookiesBanner.parag4')}</p>
                <br />
                <p>{t('cookiesBanner.parag5')}</p>
                <p>{t('cookiesBanner.parag5_1')} </p>
                <br />
                <p>
                  {t('cookiesBanner.parag6')}
                  <a id="ECE-consent-optout" href="#" rel="noopener noreferrer">
                    {t('cookiesBanner.here')}
                  </a>
                  .{t('cookiesBanner.parag6_1')}
                </p>
                <p>
                  {t('cookiesBanner.parag7')}
                  <a
                    href="https://www.cnil.fr/fr/transferts-de-donnees-hors-ue-ce-qui-change-avec-le-reglement-general-sur-la-protection-des-donnees"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('cookiesBanner.parag7Link')}
                  </a>
                </p>
                <br />
                <p>{t('cookiesBanner.parag8')} </p>

                <p>
                  {t('cookiesBanner.parag9')}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${url}ECE-Charte_de_gestion_des_cookies-SUEZ_RV.pdf`}
                  >
                    {t('cookiesBanner.PC')}{' '}
                  </a>
                  {t('cookiesBanner.parag10')}
                </p>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  )
}

export default CookiesBanner
