/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SzNotification } from 'react-theme-components'
import { AppContext } from '../../store/context'
import { truncateString } from '../../utils/ts-utils'
import EceEntity from '../../utils/interfaces/Notifications/NotificationECE'
import ROUTES from '../../utils/ROUTES'
import Loading from '../Loading'
import {
  getNotifications,
  updateNotification,
} from '../../services/Notifications/notification-services'

const NotificationMenu: React.FC = () => {
  const {
    state: {
      user: { idContactWdh },
    },
  } = useContext(AppContext)
  const { t } = useTranslation()
  const history = useHistory()
  const [show, setShow] = useState(false)
  const [myNotifications, setMyNotification] = useState<EceEntity[]>([])
  // Le state (myNotifications) garde uniquement les 4 dernières notifs, on ne peut pas se baser dessus pour faire le comptage
  const [notificationsCount, setNotificationsCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const goToMyNotifications = () => {
    history.push(ROUTES.Notifications)
    setShow(false)
  }

  const onClickNotificationItem = (notification: EceEntity) => {
    // Appel API vers NH => Non lu vers Lu
    if (!notification.seeApplication) updateNotification(notification.id)

    history.push(ROUTES.Notifications, {
      id: notification.id,
    })
    setShow(false)
  }

  const getMyNotifications = async () => {
    setIsLoading(true)

    // Idéalement, on peut se contenter de charger uniquement les 5 premiers résultats.
    // Mais pour homogénéiser l'affichage avec la liste des notifications (problème sur les dates d'envoi = null),
    // nous chargeons les 10 premiers résultats, on filtre date !== null puis on garde les 5 premières
    const results = await getNotifications(1, 10, idContactWdh)
    setMyNotification(results.slice(0, 4))
    setNotificationsCount(results.length)
    setIsLoading(false)
  }

  useEffect(() => {
    getMyNotifications()
  }, [])

  useEffect(() => {
    if (show) getMyNotifications()
  }, [show])

  return (
    <div
      className="notification-wrap"
      tabIndex={-1}
      onBlur={() => setShow(false)}
    >
      <div className="notification-icon" onClick={() => setShow(!show)}>
        <SzNotification />
        {myNotifications.filter((notif: EceEntity) => !notif.seeApplication)
          .length > 0 && <span className="pastille" />}
        <div className="label">{t('Notifications.title')}</div>
      </div>
      {show && (
        <div className="notification-block">
          <div className="title">{t('Notifications.Menu.title')}</div>
          {notificationsCount === 0 ? (
            <div className="empty">{t('Notifications.Menu.empty')}</div>
          ) : (
            <Loading isLoading={isLoading}>
              {myNotifications.map((notif: EceEntity) => (
                <div
                  className={`item ${notif.seeApplication ? '' : 'notSeen'}`}
                  key={notif.id}
                  onClick={() => onClickNotificationItem(notif)}
                >
                  {truncateString(notif.messageObjet, 60)}
                </div>
              ))}
              {notificationsCount > 4 && (
                <div className="more" onClick={goToMyNotifications}>
                  ...
                </div>
              )}
            </Loading>
          )}
          <div className="button all" onClick={goToMyNotifications}>
            {t('Notifications.title')}
          </div>
        </div>
      )}
    </div>
  )
}

export default NotificationMenu
