/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, {
  Suspense,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import './utils/i18n'

import Cookies from 'universal-cookie'
import axios from 'axios'
import Loading from './components/Loading'
import Screen from './layouts/Screen'
import ROUTES, { DASHBOARD_ROUTES } from './utils/ROUTES'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import Footer from './components/Footer'
import AutoRefreshToken from './components/AutoRefreshToken'
import IsEligibleToAccessNoAuthPages from './components/IsEligibleToAccessNoAuthPages'
import { AppContext } from './store/context'
import AuthenticatedScreenContainer from './layouts/AuthenticatedScreenContainer'
import DownForMaintenance from './components/DownForMaintenance'
import ScreenContainerWithBackground, {
  LAYOUT_CENTER,
} from './layouts/ScreenContainerWithBackground'
import ConnectionLayoutWithImageHeader from './layouts/login/ConnectionLayoutWithImageHeader'
import CenterStepsHeaderLayout from './layouts/signUp/CenterStepsHeaderLayout'
import bgLoginCreate from './assets/bg_login_create.svg'
import ScreenContainerWithBackgroundWrapper from './layouts/login/ScreenContainerWithBackgroundWrapper'
import CookiesBanner from './components/CookiesBanner'
import ErrorBoundary from './pages/ErrorBoundary'
import SuccessNotification, {
  NOTIF_MAIL_PASSWORD_SENT,
  NOTIF_SIGNUP_CREATED,
  NOTIF_SIGN_UP_LINK,
} from './pages/SuccessNotification'
import ModifyUserEmail from './pages/ModifyUserEmail'
import EmailModified from './pages/EmailModified'
import EmailModifiedFail from './pages/EmailModifiedFail'
import getCheckMaintenance, {
  checkAccessHP,
} from './services/maintenance-service'
import MAINTENANCE_TYPE from './utils/maintenance-utils'

const ErrorPage = React.lazy(() => import('./pages/ErrorPage'))
const LoginCreateUser = React.lazy(() => import('./pages/LoginCreateUser'))
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'))
const PasswordModified = React.lazy(() => import('./pages/PasswordModified'))
const NewPassword = React.lazy(() => import('./pages/NewPassword'))
const ContactUs = React.lazy(() => import('./pages/ContactUs'))
const Help = React.lazy(() => import('./pages/Help'))
const ContactSent = React.lazy(() => import('./pages/ContactSent'))
const UserSignUpEmailCheck = React.lazy(
  () => import('./pages/UserSignUpEmailCheck')
)
const UserSignUpCompanyCheck = React.lazy(
  () => import('./pages/UserSignUpCompanyCheck')
)
const UserSignUpInfos = React.lazy(() => import('./pages/UserSignUpInfos'))

const SessionExpired = React.lazy(() => import('./pages/SessionExpired'))
const UploadRegister = React.lazy(
  () => import('./pages/WasteRegister/UploadRegister')
)

declare global {
  interface Window {
    dataLayer: any[]
    QSI: any
  }
}

function App() {
  const cookies = new Cookies()
  const {
    state: { user },
  } = useContext(AppContext)
  const { roleNames } = useContext(AppContext)
  const location = useLocation()
  const history = useHistory()
  const maintenanceMode = localStorage.getItem(
    `maintenanceMode${MAINTENANCE_TYPE.ALL}`
  )

  // Manage cookies consent
  const [decision, setDecision] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false)

  const filtredDashboardRoutes = DASHBOARD_ROUTES?.filter(
    (route) => route !== ROUTES.Administration
  )

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
      setTimeout(() => setDecision(true), 1500)
    }
  }, [decision, firstRender])

  const handleCookiesDecision = (choice: boolean) => {
    setDecision(choice)
    setShowRecaptcha(!choice)
  }

  const isUserConnected = useMemo(() => {
    const refreshToken = localStorage.getItem('refresh_token')
    const roles = cookies.get('current_user_roles')
    return refreshToken && roles
  }, [user.roleNames])

  // GTM Data layers - For all pages
  //---------------------------------------
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'sz_conversion',
      sz_conversion_type: 'Cumul de visites des pages',
      userType: user.isInternal ? 'Interne' : 'Externe',
      userRole: roleNames,
    })
  }, [location])
  //--------------------------------------

  // Check Maintenance - For all pages
  useEffect(() => {
    async function checkMaintenance() {
      const maintenances = await getCheckMaintenance()
      Object.keys(maintenances).forEach((type) => {
        localStorage.setItem(`maintenanceMode${type}`, maintenances[type])
      })
    }
    checkMaintenance()
    if (process.env.REACT_APP_ENVIRONMENT !== 'prod') checkAccessHP()
  }, [location])

  /** ****************
  Quatrics
  ******************* */
  // Loads the Site Intercept code for any defined intercepts or creatives
  useEffect(() => {
    if (window.QSI && window.QSI.API) {
      window.QSI.API.unload()
      // Loads the Site Intercept code for any defined intercepts or creatives
      window.QSI.API.load()
      window.QSI.API.run()
    }
  }, [location])

  // Axios interceptors for maitenance check
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response?.status === 503 &&
        error?.config?.url.includes(process.env.REACT_APP_BASE_API_URL)
      ) {
        localStorage.setItem(`maintenanceMode${MAINTENANCE_TYPE.ALL}`, 'true')
        history.push(ROUTES.MaintenanceError)
        return
      }
      return Promise.reject(error)
    }
  )

  return (
    <>
      <Router>
        <Screen>
          <Suspense fallback={<Loading />}>
            <Switch>
              {/* To manage maintenance */}
              {(Number(`${process.env.REACT_APP_MAINTENANCE}`) === 1 ||
                maintenanceMode === 'true') && (
                <>
                  <DownForMaintenance />
                  <Route>
                    <ErrorPage connected={isUserConnected} />
                  </Route>
                </>
              )}

              {/* CONNECTION PAGE */}
              <Route exact path={['/', ROUTES.CheckEmail]}>
                <ErrorBoundary>
                  <IsEligibleToAccessNoAuthPages>
                    <ScreenContainerWithBackgroundWrapper
                      backgroundUrl={bgLoginCreate}
                    >
                      <ConnectionLayoutWithImageHeader />
                      <ScreenContainerWithBackground>
                        <LoginCreateUser showRecaptcha={showRecaptcha} />
                      </ScreenContainerWithBackground>
                      {decision && !user?.isInternal ? (
                        <CookiesBanner handleDecision={handleCookiesDecision} />
                      ) : (
                        <></>
                      )}
                      <Footer />
                    </ScreenContainerWithBackgroundWrapper>
                  </IsEligibleToAccessNoAuthPages>
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.Login}>
                <ErrorBoundary>
                  <IsEligibleToAccessNoAuthPages>
                    <ScreenContainerWithBackgroundWrapper
                      backgroundUrl={bgLoginCreate}
                    >
                      <ConnectionLayoutWithImageHeader />
                      <ScreenContainerWithBackground>
                        <LoginCreateUser showRecaptcha={showRecaptcha} />
                      </ScreenContainerWithBackground>
                      <Footer />
                    </ScreenContainerWithBackgroundWrapper>
                  </IsEligibleToAccessNoAuthPages>
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.UserSignUp}>
                <ErrorBoundary>
                  <IsEligibleToAccessNoAuthPages>
                    <ScreenContainerWithBackgroundWrapper
                      backgroundUrl={bgLoginCreate}
                    >
                      <CenterStepsHeaderLayout />
                      <ScreenContainerWithBackground type={LAYOUT_CENTER}>
                        <UserSignUpEmailCheck />
                      </ScreenContainerWithBackground>
                      <Footer />
                    </ScreenContainerWithBackgroundWrapper>
                  </IsEligibleToAccessNoAuthPages>
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.UserSignUpClient}>
                <ErrorBoundary>
                  <IsEligibleToAccessNoAuthPages>
                    <ScreenContainerWithBackgroundWrapper
                      backgroundUrl={bgLoginCreate}
                    >
                      <CenterStepsHeaderLayout />
                      <ScreenContainerWithBackground type={LAYOUT_CENTER}>
                        <UserSignUpCompanyCheck />
                      </ScreenContainerWithBackground>
                      <Footer />
                    </ScreenContainerWithBackgroundWrapper>
                  </IsEligibleToAccessNoAuthPages>
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.UserSignUpInfos}>
                <ErrorBoundary>
                  <IsEligibleToAccessNoAuthPages>
                    <ScreenContainerWithBackgroundWrapper
                      backgroundUrl={bgLoginCreate}
                    >
                      <CenterStepsHeaderLayout />
                      <ScreenContainerWithBackground type={LAYOUT_CENTER}>
                        <UserSignUpInfos />
                      </ScreenContainerWithBackground>
                      <Footer />
                    </ScreenContainerWithBackgroundWrapper>
                  </IsEligibleToAccessNoAuthPages>
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.UserSignUpCreated}>
                <ErrorBoundary>
                  <SuccessNotification
                    type={NOTIF_SIGNUP_CREATED}
                    redirectTo={ROUTES.CheckEmail}
                  />
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.MailPasswordSent}>
                <ErrorBoundary>
                  <SuccessNotification
                    type={NOTIF_MAIL_PASSWORD_SENT}
                    redirectTo={ROUTES.Login}
                  />
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.NotifSignUp}>
                <ErrorBoundary>
                  <SuccessNotification
                    type={NOTIF_SIGN_UP_LINK}
                    redirectTo={ROUTES.UserSignUp}
                    showButton={false}
                    showIcon={false}
                  />
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.NewPassword}>
                <ErrorBoundary>
                  <AutoRefreshToken />
                  <NewPassword />
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.ContactUs}>
                <ErrorBoundary>
                  {isUserConnected && <AutoRefreshToken />}
                  <ContactUs connected={isUserConnected} />
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.help}>
                <ErrorBoundary>
                  <ScreenContainerWithBackgroundWrapper
                    backgroundUrl={bgLoginCreate}
                  >
                    <ConnectionLayoutWithImageHeader />
                    <Help />
                  </ScreenContainerWithBackgroundWrapper>
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.ContactSent}>
                <ErrorBoundary>
                  <ContactSent connected={isUserConnected} />
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.CreatePassword}>
                <ErrorBoundary>
                  <AutoRefreshToken />
                  <ResetPassword mode="creation" connected={isUserConnected} />
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.ResetPassword}>
                <ErrorBoundary>
                  <AutoRefreshToken />
                  <ResetPassword connected={isUserConnected} />
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.ModifyUserEmail}>
                <ErrorBoundary>
                  <AutoRefreshToken />
                  <ModifyUserEmail
                    external={!user.isInternal}
                    connected={isUserConnected}
                  />
                </ErrorBoundary>
              </Route>
              <Route path={ROUTES.EmailModified}>
                <ErrorBoundary>
                  <EmailModified />
                </ErrorBoundary>
              </Route>
              <Route path={ROUTES.EmailModifiedFail}>
                <ErrorBoundary>
                  <EmailModifiedFail />
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.SessionExpired}>
                <ErrorBoundary>
                  <SessionExpired />
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.PasswordModified}>
                <ErrorBoundary>
                  <PasswordModified connected={isUserConnected} />
                </ErrorBoundary>
              </Route>
              <Route exact path={filtredDashboardRoutes}>
                <ErrorBoundary>
                  <AutoRefreshToken />
                  <AuthenticatedScreenContainer>
                    {roleNames.length > 0 && <AuthenticatedRoutes />}
                  </AuthenticatedScreenContainer>
                </ErrorBoundary>
              </Route>
              <Route exact path={ROUTES.UploadRegister}>
                <ErrorBoundary>
                  <AutoRefreshToken />
                  <UploadRegister />
                </ErrorBoundary>
              </Route>
              <Route>
                <ErrorBoundary>
                  <AutoRefreshToken />
                  <ErrorPage connected={isUserConnected} />
                </ErrorBoundary>
              </Route>
            </Switch>
          </Suspense>
        </Screen>
      </Router>
    </>
  )
}

export default App
