import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MAINTENANCE_TYPE from '../utils/maintenance-utils'

import ErrorInfo from '../components/ErrorInfo'
import ROUTES from '../utils/ROUTES'

export const prodURL = 'https://espace-entreprises-rv.suez.fr'

interface Props {
  connected: boolean
}

const ErrorPage: React.FC<Props> = ({ connected }: Props) => {
  interface stateType {
    type: string
  }
  const { t } = useTranslation()
  const { state } = useLocation<stateType>()

  const getMaintenaceDescription = (type: string = MAINTENANCE_TYPE.ALL) => {
    switch (type) {
      case MAINTENANCE_TYPE.DEMANDS:
        return (
          <>
            <p>{t('error.maintenanceError.demands.description.part1')}</p>
            <p>{t('error.maintenanceError.demands.description.part2')}</p>
            <p>{t('error.maintenanceError.demands.description.part3')}</p>
            <p>{t('error.maintenanceError.demands.description.part4')}</p>
          </>
        )
      default:
        return t('error.maintenanceError.all.description')
    }
  }

  return (
    <Switch>
      <Route exact path={ROUTES.DeniedAccess}>
        <ErrorInfo
          title={t('error.deniedAccess.title')}
          description={t('error.deniedAccess.description')}
          type="deniedAccess"
          connected={connected}
        />
      </Route>
      <Route exact path={ROUTES.TechnicalError}>
        <ErrorInfo
          title={t('error.technicalError.title')}
          description={t('error.technicalError.simpleDescription')}
          type="technicalError"
          connected={connected}
        />
      </Route>
      <Route exact path={ROUTES.MaintenanceError}>
        <ErrorInfo
          title={t('error.maintenanceError.all.title')}
          description={getMaintenaceDescription(state?.type)}
          chatbot={!state?.type}
          type={state?.type ? 'partialMaintenanceError' : 'maintenanceError'}
          connected={connected}
        />
      </Route>
      <Route exact path={ROUTES.OldUrls}>
        <ErrorInfo
          title=""
          description={t('error.oldUrls.description')}
          type="notFound"
          chatbot={false}
          textButton={t('error.oldUrls.textButton')}
          externalLink={prodURL}
          connected={connected}
        />
      </Route>
      <Route>
        <ErrorInfo
          title={t('error.notFound.title')}
          description={t('error.notFound.description')}
          type="notFound"
          connected={connected}
        />
      </Route>
    </Switch>
  )
}

export default ErrorPage
