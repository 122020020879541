import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'

import notfound from '../assets/notfound.png'
import deniedAccess from '../assets/deniedAccess.png'
import technicalError from '../assets/technicalError.png'
import maintenanceError from '../assets/ongoingMaintenance.png'
import Button from './Button'
import handleNavigation from '../utils/RedirectionUtils'
import { UserData } from '../services/user-services'
import ERROR_TYPES from '../utils/Error'
import { AppContext } from '../store/context'
import ROUTES from '../utils/ROUTES'
import { ActionTypes } from '../store/actions'
import headerAvatar from '../assets/Header-Avatar.png'

interface Props {
  title: string
  description: string | JSX.Element
  type: string
  chatbot?: boolean
  textButton?: string
  externalLink?: string
}

const ErrorBody: React.FC<Props> = ({
  title,
  description,
  type,
  chatbot,
  textButton,
  externalLink,
}: Props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const {
    state: {
      impersonnation: { isImpersonnation },
    },
    dispatch,
  } = React.useContext(AppContext)

  const cookies = new Cookies()
  const roles = cookies.get('current_user_roles')

  const userData = {} as UserData
  userData.roleNames = roles ? [...roles] : []

  const renderImage = () => {
    switch (type) {
      case 'notFound':
        return notfound
      case 'deniedAccess':
        return deniedAccess
      case 'technicalError':
        return technicalError
      case 'maintenanceError':
      case 'partialMaintenanceError':
        return maintenanceError
      default:
        return notfound
    }
  }
  return (
    <div className="errorbody">
      <img
        src={renderImage()}
        alt="man holding empty box"
        className="errorbody_image"
      />
      <div className="errorbody_text-container">
        <div className="content">
          {title && <h2 className="title">{title}</h2>}
          <div>
            {chatbot && (
              <img
                src={headerAvatar}
                className="img-fluid mb-5"
                alt="header-avatar"
              />
            )}
            <div>{description}</div>
            {chatbot && <p>{t('error.chatbotLabel')}</p>}
          </div>
          {type !== ERROR_TYPES.MAINTENANCE_ERROR &&
          type !== ERROR_TYPES.PARTIAL_MAINTENANCE_ERROR ? (
            <Button
              onClick={() => {
                if (externalLink) {
                  window.location.href = externalLink
                  return
                }
                if (isImpersonnation) {
                  // reset Organisations and Institutions
                  dispatch({
                    type: ActionTypes.SET_ORGANIZATION_LIST,
                    payload: {
                      organizationList: [],
                    },
                  })
                  dispatch({
                    type: ActionTypes.SET_INSTITUTION_LIST,
                    payload: {
                      institutionList: [],
                    },
                  })
                  dispatch({
                    type: ActionTypes.RESET_PERIMETER_FILTER,
                  })
                  dispatch({
                    type: ActionTypes.SET_IS_IMPERSONNATION,
                    payload: {
                      isImpersonnation: false,
                    },
                  })
                  dispatch({
                    type: ActionTypes.SET_API_CALL_BLOCK,
                    payload: {
                      APICallBlocked: false,
                    },
                  })
                  history.push(ROUTES.Home)
                } else handleNavigation(userData.roleNames, history)
              }}
            >
              {textButton || t('error.backHome')}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

ErrorBody.defaultProps = {
  chatbot: true,
  textButton: undefined,
  externalLink: undefined,
}

export default ErrorBody
