/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react'

import SearchResultItem from '../utils/SearchResultItem'
import Checkbox from './Checkbox'

interface Props {
  items?: SearchResultItem[]
  selectedItemIds: string[]
  displayCheckbox?: boolean
  topComponent?: React.ReactNode
  bottomComponent?: React.ReactNode
  onItemSelected: (value: string) => void
  onItemUnSelected: (value: string) => void
}

const SmartList: FC<Props> = ({
  items,
  selectedItemIds,
  displayCheckbox,
  topComponent,
  bottomComponent,
  onItemSelected,
  onItemUnSelected,
}: Props) => {
  return (
    <div className="smart-list">
      <div className="smart-list__list">
        {topComponent}
        {items?.map(({ id, line1, line2 }) => {
          const isSelected = selectedItemIds.includes(id)
          return (
            <div
              key={id}
              className="smart-list__item"
              onClick={() => {
                if (isSelected) {
                  onItemUnSelected(id)
                } else {
                  onItemSelected(id)
                }
              }}
            >
              {displayCheckbox ? (
                <div className="smart-list__checkbox">
                  <Checkbox checked={isSelected} />
                </div>
              ) : null}
              <div className="smart-list__content">
                <div className="smart-list__item-line1">{line1}</div>
                <div className="smart-list__item-line2">{line2}</div>
              </div>
            </div>
          )
        })}
      </div>
      {bottomComponent}
    </div>
  )
}

SmartList.defaultProps = {
  items: [],
  displayCheckbox: true,
  topComponent: <></>,
  bottomComponent: <></>,
}

export default SmartList
