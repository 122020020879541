/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import logo from '../assets/logo.png'
import ROUTES from '../utils/ROUTES'
import { AppContext } from '../store/context'
import handleNavigation from '../utils/RedirectionUtils'
import { UserData } from '../services/user-services'
import { prodURL } from '../pages/ErrorPage'
import { ActionTypes } from '../store/actions'

interface Props {
  isLogoClickable?: boolean
}

const Header: React.FC<Props> = ({ isLogoClickable }: Props) => {
  const {
    state: {
      user,
      impersonnation: { isImpersonnation },
    },
    dispatch,
  } = useContext(AppContext)

  const cookies = new Cookies()

  const roleName = useMemo(() => {
    const refreshToken = localStorage.getItem('refresh_token')
    const roles = cookies.get('current_user_roles')
    return refreshToken && roles
  }, [user])

  const history = useHistory()

  const onLogoClick = () => {
    const pathName = window.location.pathname
    if (pathName === ROUTES.OldUrls) {
      window.location.href = prodURL
      return false
    }
    if (isImpersonnation) {
      // reset Organisations and Institutions
      dispatch({
        type: ActionTypes.SET_ORGANIZATION_LIST,
        payload: {
          organizationList: [],
        },
      })
      dispatch({
        type: ActionTypes.SET_INSTITUTION_LIST,
        payload: {
          institutionList: [],
        },
      })
      dispatch({
        type: ActionTypes.RESET_PERIMETER_FILTER,
      })
      dispatch({
        type: ActionTypes.SET_IS_IMPERSONNATION,
        payload: {
          isImpersonnation: false,
        },
      })
    }
    return (
      isLogoClickable &&
      (roleName
        ? handleNavigation(roleName, history)
        : history.push(ROUTES.CheckEmail))
    )
  }

  return (
    <div className="header">
      <div className="header__content">
        <img
          src={logo}
          alt="suez logo"
          className={`header_logo_${isLogoClickable ? 'click' : 'disabled'}`}
          onClick={onLogoClick}
        />
      </div>
    </div>
  )
}

Header.defaultProps = {
  isLogoClickable: true,
}

export default Header
