import React from 'react'
import { useHistory } from 'react-router-dom'
import ROUTES from '../utils/ROUTES'

const DownForMaintenance: React.FC = () => {
  const history = useHistory()

  // Redirect to the maintenance page
  React.useEffect(() => {
    history.push(ROUTES.MaintenanceError)
  }, [])

  return null
}

export default DownForMaintenance
