/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../store/context'
import InstitutionEntity from '../utils/InstitutionEntity'
import Table from './Table'
import { distinctArray } from '../utils/array-utils'
import { getInstitutionsForTable } from '../services/institution-services'
import { isCancel } from '../services/billing-services'
import { getNoDataMessage, TAB_INSTITUTIONS } from '../utils/TableUtils'
import SvgIcon from './SvgIcon'
import { filterBuilder } from '../utils/interfaces/FilterBuilderInterface'
import EnumFilterType from '../utils/enums/filters/FilterBuilder'
import FilterBuilder from './filters/FilterBuilder'

interface Props {
  onInstitutionSelected: (ids: string[]) => void
  onDataInstitutionSelected: (data: InstitutionEntity[]) => void
  institutionItemsDatas: InstitutionEntity[]
  finalKeptIds: string[]
  setFinalList: React.Dispatch<React.SetStateAction<string[]>>
}

function WasteRegisterSelector({
  onInstitutionSelected,
  onDataInstitutionSelected,
  institutionItemsDatas = [],
  finalKeptIds,
  setFinalList,
}: Props) {
  const { t } = useTranslation()
  const {
    state: { user, institution, organization },
  } = useContext(AppContext)
  const history = useHistory()
  // STATE
  const [isLoading, setIsLoading] = useState(false)
  const [tableDatas, setTableDatas] = useState<any[]>([])
  const [isResetSelection, setIsResetSelection] = useState(false)
  const [resultList, setResultList] = useState<string[]>([])
  const [institutions, setInstitutions] = React.useState<InstitutionEntity[]>(
    []
  )
  const [nbPage, setNbPage] = useState<number>(0)
  const [tablePageCount, setTablePageCount] = useState(0)
  const [filters, setFilters] = React.useState<{ [key: string]: string[] }>({})
  const [resetOrgaInsti, setResetOrgaInsti] = useState(false)
  const [nbPerPage, setNbPerPage] = useState(
    process.env.REACT_APP_TABLE_DEFAULT_RESULT_PER_PAGE
      ? parseInt(process.env.REACT_APP_TABLE_DEFAULT_RESULT_PER_PAGE, 10)
      : 10
  )
  const itemsSelectedLabel = `${
    resultList.length <= 1
      ? t('generic.selected.singular')
      : t('generic.selected.plural')
  }`

  // Headers
  const INSTUTIONS_TABLE = [
    {
      Header: 'Nom entreprise',
      accessor: 'raisonSociale',
    },
    {
      Header: 'Adresse',
      accessor: 'searchAdresse',
    },
    {
      Header: 'CP - Ville',
      accessor: 'searchVille',
    },
    {
      Header: 'N° client',
      accessor: 'numClient',
    },
  ]

  // HANDLER
  const checkAll = (itemIds: string[]) => {
    const unchecked: string[] = []

    itemIds.forEach((instId: string) => {
      if (!resultList.includes(instId)) {
        unchecked.push(instId)
      }
    })

    if (unchecked.length > 0) {
      // add the previously unselected institutions to the list of selected institutions in this component and in the parent
      const resultingList = finalKeptIds.concat(unchecked)
      setResultList([...resultingList])
      onInstitutionSelected(distinctArray([...resultingList]))

      // update the list of institutions to be displayed in the generator component
      const missingListData = institutions.filter((inst: InstitutionEntity) =>
        unchecked.includes(inst.id)
      )
      const newInstData = institutionItemsDatas.concat(missingListData)

      onDataInstitutionSelected(newInstData)
    } else {
      // remove all institutions displayed in the current selector page from the list of selected institutions
      const resultingListWhenAllChecked = finalKeptIds.filter(
        (selected) => !itemIds.includes(selected)
      )

      setResultList([...resultingListWhenAllChecked])
      onInstitutionSelected(distinctArray([...resultingListWhenAllChecked]))

      // update the list of institutions to be displayed in the generator component
      const filteredListWithRemove = institutionItemsDatas.filter(
        (instData: InstitutionEntity) =>
          resultingListWhenAllChecked.includes(instData.id)
      )

      onDataInstitutionSelected(filteredListWithRemove)
    }
  }

  const checkOne = (itemId: string) => {
    let newResultList: string[] = []
    if (resultList.length === 0) {
      newResultList.push(itemId)
    } else {
      newResultList = resultList.includes(itemId)
        ? resultList.filter((id: string) => id !== itemId)
        : resultList.concat([itemId])
    }

    setResultList([...distinctArray(newResultList)])
    onInstitutionSelected(distinctArray([...newResultList]))

    institutions.forEach((elem: InstitutionEntity) => {
      if (!institutionItemsDatas.includes(elem))
        institutionItemsDatas.push(elem)
    })
    onDataInstitutionSelected(institutionItemsDatas)
  }

  const formatedInstitutionsData = (institutionsItems: InstitutionEntity[]) => {
    return institutionsItems.map((institutionItem) => {
      return {
        id: institutionItem.id,
        raisonSociale: (
          <div className="wasteRegister__raisonSociale">
            {institutionItem.name}
          </div>
        ),
        searchAdresse: (
          <div className="wasteRegister__searchAdresse">
            {institutionItem.shortAdress}
          </div>
        ),
        searchVille: (
          <div className="wasteRegister__searchVille ">
            {institutionItem.villeCP}
          </div>
        ),
        numClient: (
          <div className="wasteRegister__numClient">
            {institutionItem.numDebiteur}
          </div>
        ),
      }
    })
  }
  async function LoadInstitionsAsync() {
    setIsLoading(true)
    const { results, pageCount } = await getInstitutionsForTable(
      nbPerPage,
      nbPage,
      organization.items,
      institution.items,
      filters,
      history
    )

    if (isCancel(results)) {
      return
    }
    setTablePageCount(pageCount)
    if (results) {
      setInstitutions(results)
      setTableDatas(formatedInstitutionsData(results))
      setIsLoading(false)
    }
    setResetOrgaInsti(false)
  }

  useEffect(() => {
    if (
      user.roleNames.length === 0 ||
      organization.items === undefined ||
      institution.items === undefined
    )
      return

    LoadInstitionsAsync()
    setIsResetSelection(true)
  }, [user, nbPage, nbPerPage, filters])

  const applyFilters = (filters: { [key: string]: any | any[] }) => {
    setFilters(filters)
    setResetOrgaInsti(true)
  }

  const resetFilters = () => {
    setFilters({})
    setResetOrgaInsti(true)
  }

  useEffect(() => {
    resetFilters()
    setTableDatas([])
    setIsResetSelection(true)
    setResetOrgaInsti(true)
    setFinalList([])
  }, [institution, organization])

  useEffect(() => {
    setResultList(() => finalKeptIds)
  }, [finalKeptIds])

  const filterBuilder: filterBuilder = {
    dynamic: [
      {
        name: 'entreprise',
        type: EnumFilterType.INPUT_STRING,
        label: 'Nom entreprise',
        placeholder: t('wasteRegister.filters.companyName'),
        apiName: 'searchEntreprise',
        multiple: true,
      },
      {
        name: 'adresse',
        type: EnumFilterType.INPUT_STRING,
        label: 'Adresse',
        placeholder: t('wasteRegister.filters.address'),
        apiName: 'searchAdresse',
        multiple: true,
      },
      {
        name: 'ville',
        type: EnumFilterType.INPUT_STRING,
        label: 'Ville, CP',
        placeholder: t('wasteRegister.filters.city'),
        apiName: 'searchVille',
        multiple: true,
      },
      {
        name: 'client',
        type: EnumFilterType.INPUT_STRING,
        label: 'N° client',
        placeholder: t('wasteRegister.filters.client'),
        apiName: 'numClient',
        multiple: true,
      },
    ],
    static: [],
  }

  return (
    <>
      <div className="wasteRegister__left">
        <div className="wasteRegister__header_selector">
          <SvgIcon name="building" className="wasteRegister_buttonicon" />
          <div className="title">{t('wasteRegister.leftTitle')}</div>
        </div>
        <div>
          <FilterBuilder
            onSubmitFilter={(filters: { [key: string]: any | any[] }) =>
              applyFilters(filters)
            }
            onResetFilter={() => resetFilters()}
            filterBuilder={filterBuilder}
            className=""
          />
        </div>
        <div>
          <div className="wasteRegister_selectedItemsCount">
            <p>
              {finalKeptIds.length} {itemsSelectedLabel}
            </p>
          </div>
        </div>
        <Table
          columns={INSTUTIONS_TABLE}
          data={tableDatas}
          checkedList={finalKeptIds}
          disabledColumnActionsList={['N° client']}
          onSelectedItemChanged={checkOne}
          onSelectionChange={null}
          onCheckAll={checkAll}
          withFilters={false}
          isLoading={isLoading}
          isInternalPagination
          nbPerPage={nbPerPage}
          pageCount={tablePageCount}
          onPageChange={setNbPage}
          onNbPerPageChange={setNbPerPage}
          resetOrgaInsti={resetOrgaInsti}
          forceReset={isResetSelection}
          noDataAvailable={getNoDataMessage(TAB_INSTITUTIONS)}
        />
      </div>
    </>
  )
}

export default WasteRegisterSelector
