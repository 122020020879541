import React from 'react'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../store/context'
import MenuItem from './MenuItem'
import MenuSubItem from '../utils/MenuSubItem'
import ROUTES from '../utils/ROUTES'

export enum CREATIONMODIFY_STEP {
  STEP1_INFOS = 'step1',
  STEP2_INSTITUTION = 'step2',
  STEP3_PERIMETER = 'step3',
}

const SubMenu = () => {
  const { t } = useTranslation()
  const [menuItems, setMenuItems] = React.useState<MenuSubItem[]>([])

  const {
    state: { createModifyUser },
  } = React.useContext(AppContext)

  React.useEffect(() => {
    const newMenuItems: MenuSubItem[] = [
      {
        title: t('subMenu.createUser.infoAndRole'),
        link: `${ROUTES.UpdateUserProfile}/${createModifyUser.userId}`,
        selected:
          createModifyUser.actualStep === CREATIONMODIFY_STEP.STEP1_INFOS,
        disabled:
          createModifyUser.actualStep === CREATIONMODIFY_STEP.STEP1_INFOS,
      },
      {
        title: t('subMenu.createUser.attachmentInstitution'),
        link: `${ROUTES.UpdateUserAttachmentInstitution}/${createModifyUser.userId}`,
        selected:
          createModifyUser.actualStep === CREATIONMODIFY_STEP.STEP2_INSTITUTION,
        disabled:
          createModifyUser.actualStep === CREATIONMODIFY_STEP.STEP2_INSTITUTION
            ? true
            : createModifyUser.step2Disabled,
      },
      {
        title: t('subMenu.createUser.perimeterCreation'),
        link: `${ROUTES.UserPerimeter}/${createModifyUser.userId}`,
        selected:
          createModifyUser.actualStep === CREATIONMODIFY_STEP.STEP3_PERIMETER,
        disabled: true,
      },
    ]
    setMenuItems(newMenuItems)
  }, [createModifyUser])

  const total = menuItems.length

  return (
    <>
      {menuItems?.map(({ title, link, selected, disabled }, index) => (
        <MenuItem
          disabled={disabled}
          key={title}
          title={
            <div className={`sub-menu__label ${disabled ? 'disabled' : ''}`}>
              <div>{`${index + 1} / ${total}`}</div>
              <div>{title}</div>
            </div>
          }
          link={link}
          className={`sub-menu__item ${selected} ${disabled ? 'disabled' : ''}`}
        />
      ))}
    </>
  )
}

export default SubMenu
