/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from 'react'
import { SzInput } from 'react-theme-components'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../store/context'
import CustomSelect from '../CustomSelect'
import Button from '../Button'
import SvgIcon from '../SvgIcon'
import { isMobile } from '../../utils/screenUtils'
import ButtonSquareIcon from '../ButtonSquareIcon'
import { truncateString } from '../../utils/ts-utils'
import EnumFilterType from '../../utils/enums/filters/FilterBuilder'
import DatePicker from '../DatePicker'
import {
  FilterDynamicItem,
  FilterStaticItem,
  StatusOption,
  arrayType,
  defaultFilter,
  filterBuilder,
  stringType,
} from '../../utils/interfaces/FilterBuilderInterface'
import Tags from './Tags'

interface Props {
  onSubmitFilter: (values: { [key: string]: any | any[] }) => void
  onResetFilter: () => void
  filterBuilder: filterBuilder
  className?: string
}

const FilterBuilder: React.FC<Props> = ({
  onSubmitFilter,
  onResetFilter,
  filterBuilder,
  className,
}: Props) => {
  const { t } = useTranslation()
  const {
    state: { institution, organization },
  } = useContext(AppContext)

  type FilterItem = FilterStaticItem | FilterDynamicItem

  const initialeDate = new Date()
  const [isMobileMode, setIsMobileMode] = useState<boolean>(
    isMobile(window.innerWidth)
  )

  const filterOptions = filterBuilder.dynamic.map((item) => ({
    label: item.label,
    value: item.name,
    type: item.type,
    multiple: item.multiple,
  }))

  const [selectedFilter, setSelectedFilter] = useState<StatusOption>(
    defaultFilter
  )
  const [resetDate, setResetDate] = useState<boolean>(false)

  const initializFiltersArray = () => {
    return filterBuilder.dynamic.reduce((acc, curr) => {
      acc[curr.name] = []
      return acc
    }, {} as arrayType)
  }

  const initializFiltersString = <T extends FilterItem>(filterItems: T[]) => {
    return filterItems.reduce((acc, curr) => {
      switch (curr.type) {
        case EnumFilterType.INPUT_DATEPICKER:
          acc[curr.name] = { startDate: null, endDate: null }
          break
        case EnumFilterType.INPUT_DROPDOWN:
          acc[curr.name] = curr.data
            ? curr.data[0]
            : { label: t('filter.chooseOption'), value: 'all' }
          break
        case EnumFilterType.INPUT_NUMBER:
          acc[curr.name] = 0
          break
        default:
          acc[curr.name] = ''
          break
      }
      return acc
    }, {} as stringType)
  }

  const [filterValues, setFiltersValues] = useState<arrayType>(
    initializFiltersArray()
  )

  const [tmpDynamicFilterState, setTmpDynamicFilterState] = useState<
    stringType
  >(initializFiltersString(filterBuilder.dynamic))

  const [tmpStaticFilterState, setTmpStaticFilterState] = useState<stringType>(
    initializFiltersString(filterBuilder.static)
  )

  const isDynamicFilterStateChanged =
    JSON.stringify(tmpDynamicFilterState) !==
    JSON.stringify(initializFiltersString(filterBuilder.dynamic))

  const isStaticFilterStateChanged =
    JSON.stringify(tmpStaticFilterState) !==
    JSON.stringify(initializFiltersString(filterBuilder.static))

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true)
  const [showModal, setShowModal] = useState(false)
  const [showTags, setShowTags] = useState<boolean>(false)
  const [fixedHeight, setFixedHeight] = useState<boolean>(false)
  const tagBlockHeight = useRef(0)
  const nbTagRows = useRef(0)

  const checkDeviceType = () => {
    setIsMobileMode(isMobile(window.innerWidth))
  }

  useLayoutEffect(() => {
    const tagsHeight = document.getElementById('filter-tags')?.clientHeight
    if (tagsHeight === undefined) {
      nbTagRows.current = 0
    }
    if (tagsHeight !== undefined) {
      if (tagsHeight > tagBlockHeight.current) {
        nbTagRows.current += 1
      }
      if (tagsHeight < tagBlockHeight.current) {
        nbTagRows.current -= 1
      }
      tagBlockHeight.current = tagsHeight
    }
  })

  useEffect(() => {
    window.addEventListener('resize', checkDeviceType)
    // Don't remove the return, otherwise you'll have memory leak
    return () => window.removeEventListener('resize', checkDeviceType)
  }, [window.innerWidth])

  useEffect(() => {
    const filterDesktopHeight = document.getElementById('filter-module-desktop')
      ?.clientHeight
    if (nbTagRows.current === 5) {
      if (filterDesktopHeight !== undefined) {
        document.documentElement.style.setProperty(
          '--max-filter-height',
          `${filterDesktopHeight}px`
        )
      }
      setFixedHeight(true)
    } else if (nbTagRows.current < 5) {
      setFixedHeight(false)
    }
  })

  useEffect(() => {
    setShowTags(
      Object.values(filterValues).some((filter: any) => filter.length > 0)
    )
  })

  useEffect(() => {
    if (
      isStaticFilterStateChanged ||
      JSON.stringify(filterValues) !== JSON.stringify(initializFiltersArray())
    ) {
      setIsSubmitDisabled(false)
    } else {
      setIsSubmitDisabled(true)
    }
  }, [tmpStaticFilterState, filterValues])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (isDynamicFilterStateChanged) {
          addTag()
        }
        if (isStaticFilterStateChanged) {
          applyFilter()
        }
      }
    }
    window.addEventListener('keypress', handleKeyPress)
    return () => window.removeEventListener('keypress', handleKeyPress)
  }, [tmpDynamicFilterState, tmpStaticFilterState])

  useEffect(() => {
    resetFilter()
  }, [institution, organization])

  const onHideUpdate = async () => {
    setShowModal(!showModal)
  }

  const resetFilter = () => {
    setTmpDynamicFilterState(initializFiltersString(filterBuilder.dynamic))
    setTmpStaticFilterState(initializFiltersString(filterBuilder.static))
    setSelectedFilter(defaultFilter)
    setFiltersValues(initializFiltersArray())
    setIsSubmitDisabled(true)
    setResetDate(true)
    onResetFilter()
  }

  const applyFilter = () => {
    // Remplacer les clés des filtres (name) par l'apiName pour éviter de les convertir dans le service
    const results: { [key: string]: any | any[] } = {}
    filterBuilder.dynamic.forEach((item: FilterDynamicItem) => {
      if (item.multiple) {
        results[item.apiName] = results.hasOwnProperty(item.apiName)
          ? [...results[item.apiName], ...filterValues[item.name]]
          : filterValues[item.name]
      } else {
        const [firstValue] = filterValues[item.name]
        results[item.apiName] = firstValue
      }
    })

    filterBuilder.static.forEach((item: FilterStaticItem) => {
      results[item.apiName] = tmpStaticFilterState[item.name]
    })
    onSubmitFilter(results)
    setShowModal(false)
  }

  const onChangeFilter = (e: StatusOption) => {
    setSelectedFilter(e)
    setTmpDynamicFilterState(initializFiltersString(filterBuilder.dynamic))
  }

  const renderFilterComponent = <T extends FilterItem>(
    filterItems: T[],
    tmpFilterState: stringType,
    setTmpFilterState: React.Dispatch<React.SetStateAction<stringType>>,
    type: string,
    value: string
  ) => {
    const placeholder = !isMobileMode
      ? truncateString(
          filterItems.find((item) => item.name === value)?.placeholder ?? '',
          25
        )
      : filterItems.find((item) => item.name === value)?.placeholder ?? ''
    const id = `${value}-input`
    const intiValue = tmpFilterState[value]

    switch (type) {
      case EnumFilterType.INPUT_DATEPICKER:
        return (
          <DatePicker
            initialstartDate={
              tmpFilterState[value].startDate
                ? new Date(tmpFilterState[value].startDate)
                : initialeDate
            }
            initialendDate={
              tmpFilterState[value].endDate
                ? new Date(tmpFilterState[value].endDate)
                : initialeDate
            }
            initialDateDisplayed={false}
            handleDatesChange={([startDate, endDate]: [
              Date | null,
              Date | null
            ]) => {
              setResetDate(false)
              setTmpFilterState({
                ...tmpFilterState,
                [value]: {
                  startDate: moment(startDate).format('DD/MM/YYYY'),
                  endDate: moment(endDate).format('DD/MM/YYYY'),
                },
              })
            }}
            resetDate={resetDate}
            isOutsideRange={(day) => {
              const today = new Date()
              today.setHours(0, 0, 0, 0)
              day.startOf('day')
              return day.isAfter(today)
            }}
          />
        )
      case EnumFilterType.INPUT_DROPDOWN:
        return (
          <CustomSelect
            id={id}
            options={filterItems.find((item) => item.name === value)?.data}
            value={tmpFilterState[value]}
            className="searchBar_select"
            name="input-1"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTmpFilterState({
                ...tmpFilterState,
                [value]: e,
              })
            }}
            height={47}
          />
        )
      case EnumFilterType.INPUT_NUMBER:
        return (
          <SzInput
            id={id}
            value={intiValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (/^[-]{0}\d{0,2}$/.test(e.target.value)) {
                setTmpFilterState({
                  ...tmpFilterState,
                  [value]: e.currentTarget.value,
                })
              }
            }}
            placeholder={placeholder}
            handleClear={() =>
              setTmpFilterState({
                ...tmpFilterState,
                [value]: '',
              })
            }
          />
        )
      case EnumFilterType.INPUT_STRING:
        return (
          <SzInput
            id={id}
            value={intiValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTmpFilterState({
                ...tmpFilterState,
                [value]: e.currentTarget.value,
              })
            }
            placeholder={placeholder}
            handleClear={() =>
              setTmpFilterState({
                ...tmpFilterState,
                [value]: '',
              })
            }
          />
        )
      default:
        return ''
    }
  }

  const displayAllElementsFilter = () => {
    return (
      <div
        className={
          !isMobileMode
            ? `filter_module_desktop_filters py-4 px-3 
                ${showTags && 'filter_module_bottom-border'}`
            : 'filter_module_mobile_filters'
        }
      >
        <div className="d-lg-flex flex-wrap d-block">
          {filterBuilder.static.length > 0 &&
            filterBuilder.static.map((element) => {
              return (
                <div
                  key={element.name}
                  className={
                    !isMobileMode ? `${element.className} mr-3` : 'row-filter'
                  }
                >
                  <div className="searchBar_label">{element.label}</div>
                  {renderFilterComponent(
                    filterBuilder.static,
                    tmpStaticFilterState,
                    setTmpStaticFilterState,
                    element.type,
                    element.name
                  )}
                </div>
              )
            })}
          {filterBuilder.dynamic.length > 0 && (
            <>
              <div className={!isMobileMode ? '' : 'row-filter'}>
                <div className="searchBar_label">{t('filter.addFilter')}</div>
                <div className="custom-select-desktop">
                  <CustomSelect
                    id="dynamic-filter"
                    options={filterOptions}
                    value={selectedFilter}
                    className="searchBar_select"
                    name="input-1"
                    onChange={onChangeFilter}
                    height={47}
                    isSearchable={false}
                  />
                  {renderFilterComponent(
                    filterBuilder.dynamic,
                    tmpDynamicFilterState,
                    setTmpDynamicFilterState,
                    selectedFilter.type ?? '',
                    selectedFilter.value
                  )}
                  {isDynamicFilterStateChanged && (
                    <button
                      type="button"
                      className="add-custom-filter-btn"
                      onClick={addTag}
                    >
                      <SvgIcon name="addSquare" />
                    </button>
                  )}
                </div>
              </div>
              {isMobileMode && showTags && (
                <Tags
                  fixedHeight={fixedHeight}
                  filterOptions={filterOptions}
                  filterValues={filterValues}
                  setFiltersValues={setFiltersValues}
                  resetFilter={resetFilter}
                  setIsSubmitDisabled={setIsSubmitDisabled}
                />
              )}
            </>
          )}
        </div>
        {actionsFilter()}
      </div>
    )
  }

  const actionsFilter = () => {
    return (
      <div className="filter_buttons">
        <ButtonSquareIcon
          iconName="synchronize"
          onClick={resetFilter}
          disabled={isSubmitDisabled}
        />
        <Button
          data-cy="applyFilter"
          disabled={isSubmitDisabled}
          onClick={applyFilter}
          className="apply-filter"
        >
          <p>{t('filter.applyFilters')}</p>
        </Button>
      </div>
    )
  }

  const addTag = () => {
    if (
      !filterValues[selectedFilter.value].includes(
        tmpDynamicFilterState[selectedFilter.value]
      ) &&
      selectedFilter.multiple
    ) {
      filterValues[selectedFilter.value].push(
        tmpDynamicFilterState[selectedFilter.value]
      )
    } else {
      filterValues[selectedFilter.value][0] =
        tmpDynamicFilterState[selectedFilter.value]
    }
    setTmpDynamicFilterState(initializFiltersString(filterBuilder.dynamic))
    setSelectedFilter(defaultFilter)
    setIsSubmitDisabled(false)
  }

  return !isMobileMode ? (
    <div
      id="filter-module-desktop"
      className={`${className} filter_module_desktop custom-dynamic-filter p-0
          ${fixedHeight && 'filter_module_desktop_fixed-height'}`}
    >
      {(filterBuilder.dynamic.length > 0 || filterBuilder.static.length > 0) &&
        displayAllElementsFilter()}
      {showTags && (
        <Tags
          fixedHeight={fixedHeight}
          filterOptions={filterOptions}
          filterValues={filterValues}
          setFiltersValues={setFiltersValues}
          resetFilter={resetFilter}
          setIsSubmitDisabled={setIsSubmitDisabled}
        />
      )}
    </div>
  ) : (
    <div className={`${className} filter_module_mobile`}>
      <Button
        className="modal_filter_button mb-3"
        onClick={() => setShowModal(true)}
      >
        <p>{t('filter.filter')}</p>
      </Button>
      <Modal
        show={showModal}
        onHide={onHideUpdate}
        centered
        backdrop="static"
        size="xl"
        className="mobile_filter_modal"
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>{t('filter.advancedSearch')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(filterBuilder.dynamic.length > 0 ||
            filterBuilder.static.length > 0) &&
            displayAllElementsFilter()}
        </Modal.Body>
      </Modal>
    </div>
  )
}

FilterBuilder.defaultProps = {
  className: '',
}

export default FilterBuilder
