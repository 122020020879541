import React from 'react'
import Alert from './Alert'

interface Props {
  msg?: string | null
}

const FormErrorMessage: React.FC<Props> = ({ msg }: Props) => {
  if (!msg) {
    return null
  }

  return <Alert type="alert" alertMessaeg={msg} />
}

FormErrorMessage.defaultProps = { msg: null }

export default FormErrorMessage
