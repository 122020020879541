/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react'
import { useHistory, Route } from 'react-router-dom'
import ROLES from '../utils/ROLES'
import ROUTES from '../utils/ROUTES'
import MAINTENANCE_TYPE from '../utils/maintenance-utils'
import { hasAccessTo, roleNamesToNumber } from '../services/roles-services'
import { AppContext } from '../store/context'

interface CustomRouteProps {
  children?: React.ReactNode
  path: string | string[]
  exact: boolean
  condition: ROLES[]
  personnalizedCondition?: boolean
  partialMaintenance?: PartialMaintenance
}

interface PartialMaintenance {
  type: MAINTENANCE_TYPE.ALL | MAINTENANCE_TYPE.DEMANDS
}

const CustomRoute = (props: CustomRouteProps) => {
  const history = useHistory()

  const {
    state: { user },
  } = React.useContext(AppContext)

  const [returnedRoute, setReturnedRoute] = useState(<Route {...props} />)

  const { condition, path, personnalizedCondition, partialMaintenance } = props

  React.useEffect(() => {
    if (user.roleNames.length === 0) {
      history.push(ROUTES.CheckEmail)
    } else if (!hasAccessTo(condition, roleNamesToNumber(user.roleNames))) {
      history.push(ROUTES.DeniedAccess)
    }

    if (personnalizedCondition) {
      history.push(ROUTES.DeniedAccess)
    }

    if (partialMaintenance?.type) {
      if (
        localStorage.getItem(`maintenanceMode${partialMaintenance?.type}`) ===
        'true'
      )
        history.push(ROUTES.MaintenanceError, {
          type: partialMaintenance.type,
        })
    }

    setReturnedRoute(<Route {...props} />)
  }, [path])

  return returnedRoute
}

export default CustomRoute
