import React from 'react'
import Header from '../components/Header'
import bgLoginCreate from '../assets/bg_login_create.svg'

interface Props {
  children: React.ReactNode
  isLogoClickable?: boolean
  isLogged?: boolean
}

const notLoggedBackground = {
  backgroundColor: '#f4f6fb',
  backgroundImage: `url(${bgLoginCreate})`,
  backgroundPosition: 'center 8rem',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '123%',
  minHeight: '100vh',
}

const ScreenContainerSimpleLayoutConnexion = ({
  children,
  isLogoClickable,
  isLogged,
}: Props) => {
  return (
    <div
      className="screen-container-simple-layout"
      style={!isLogged ? notLoggedBackground : {}}
    >
      <Header isLogoClickable={isLogoClickable} />
      <div className="screen-container-simple-layout__children">{children}</div>
    </div>
  )
}

ScreenContainerSimpleLayoutConnexion.defaultProps = {
  isLogoClickable: true,
  isLogged: false,
}

export default ScreenContainerSimpleLayoutConnexion
