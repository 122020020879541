import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Button from '../components/Button'
import ROUTES from '../utils/ROUTES'
import ScreenContainerSimpleLayoutConnexion from '../layouts/ScreenContainerSimpleLayoutConnexion'
import { GlobalErrorMessage } from '../services/connexion-services'

const EmailModifiedFail: React.FC = () => {
  interface stateType {
    reason: string
  }

  const history = useHistory()
  const { t } = useTranslation()
  const { state } = useLocation<stateType>()

  return (
    <ScreenContainerSimpleLayoutConnexion isLogged={false}>
      <div className="email-modified">
        <div className="email-modified_text-container">
          <div className="content">
            {state?.reason === GlobalErrorMessage.UNAVAILABLE_EMAIL ? (
              <>
                <h2 className="title">
                  {t('emailModifiedFail.unavailableEmail.title')}
                </h2>
                <div>
                  <p className="infos">
                    {t('emailModifiedFail.unavailableEmail.info')}
                  </p>
                </div>
              </>
            ) : (
              <>
                <h2 className="title">
                  {t('emailModifiedFail.expiredLink.title')}
                </h2>
                <div>
                  <p className="infos">
                    {t('emailModifiedFail.expiredLink.info')}
                  </p>
                </div>
              </>
            )}
            <Button onClick={() => history.push(ROUTES.ModifyUserEmail)}>
              {t('emailModifiedFail.button')}
            </Button>
          </div>
        </div>
      </div>
    </ScreenContainerSimpleLayoutConnexion>
  )
}

export default EmailModifiedFail
