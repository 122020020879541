/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { IDecodedToken } from '../utils/interfaces/DecodedTokenInterface'
import { loginWithRefreshToken } from './connexion-services'

export default async function interceptorImpersonnation(axiosInterceptor: any) {
  // Nettoyer les interceptors
  axios.interceptors.request.clear()

  axiosInterceptor = axios.interceptors.request.use(
    (config: any) => {
      const impersonationToken = sessionStorage.getItem('impersonation_token')
      /* eslint-disable-next-line no-param-reassign */
      config.headers.Authorization = `Bearer ${impersonationToken}`

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )
}

export async function interceptorNavigation(
  axiosInterceptor: any,
  emailImpersonated: string
) {
  let isRefreshing = false

  axiosInterceptor = axios.interceptors.request.use(
    async (config: any) => {
      const accessToken = localStorage.getItem('access_token')
      const refreshToken = localStorage.getItem('refresh_token')

      if (
        (config.url &&
          config.url.endsWith('/login') &&
          config.method === 'post') ||
        isRefreshing
      ) {
        return config
      }

      if (config.method === 'patch') {
        /* eslint-disable-next-line no-param-reassign */
        config.headers['Content-Type'] = 'application/merge-patch+json'
      }

      if (
        accessToken !== null &&
        refreshToken != null &&
        emailImpersonated === ''
      ) {
        const decodAccessToken: IDecodedToken = jwt_decode(accessToken || '')
        // actualise le  access_token et refresh_token si la date d'expiration du token actuel est proche(inférieur à une minute)
        if (
          decodAccessToken.exp - Date.now() / 1000 <= 60 &&
          emailImpersonated === ''
        ) {
          isRefreshing = true
          await loginWithRefreshToken(refreshToken || '')
            .then(() => {
              /* eslint-disable-next-line no-param-reassign */
              config.headers.Authorization = `Bearer ${localStorage.getItem(
                'access_token'
              )}`

              isRefreshing = false

              return config
            })
            .catch((e) => {
              isRefreshing = false
            })
          return config
        }
        /* eslint-disable-next-line no-param-reassign */
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          'access_token'
        )}`
      } else {
        axios.interceptors.request.eject(axiosInterceptor)
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )
}
