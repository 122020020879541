import React, { ReactNode, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { SzInput } from 'react-theme-components'
import SearchResultItem from '../utils/SearchResultItem'
import Button from './Button'
import Input from './Input'
import Loading from './Loading'
import SearchSelected from './SearchSelected'
import SmartList from './SmartList'
import StyledSmartListe from './atom/BandeauPerimetre/SmartList'

interface Props {
  items?: SearchResultItem[]
  selectedItems: SearchResultItem[]
  countLabel: string
  displayCheckbox?: boolean
  count: number
  selectedPlaceholder?: ReactNode
  isLoading: boolean
  displaySelected: boolean
  forceReset?: boolean
  isCollapsedSearch?: boolean
  resetSearchValue?: boolean
  isOrga?: boolean
  onSearchChanged: (value: string) => void
  onSearchValidated: (seletedItem: string[]) => void
  onHandlerCollapsedSearch?: (SearchValue: string) => void
  onItemSelected: (value: string) => void
  onItemUnSelected: (value: string) => void
  disabledSearch?: boolean
  isBandeau?: boolean
  dataTest?: string
  organizationSetted?: boolean
}

const REACT_APP_FILTER_MAX_ORGANIZATION = Number(
  process.env.REACT_APP_FILTER_MAX_ORGANIZATION
)

const REACT_APP_BASE_API_WDH_MAX_RESULT = Number(
  process.env.REACT_APP_BASE_API_WDH_MAX_RESULT
)

const SearchWithList: React.FC<Props> = ({
  items,
  selectedItems,
  countLabel,
  displayCheckbox,
  count,
  isOrga,
  selectedPlaceholder,
  isLoading,
  displaySelected,
  forceReset,
  onSearchChanged,
  onSearchValidated,
  isCollapsedSearch,
  resetSearchValue,
  onHandlerCollapsedSearch,
  onItemSelected,
  onItemUnSelected,
  disabledSearch,
  isBandeau,
  dataTest,
  organizationSetted,
}: Props) => {
  const { t } = useTranslation()
  // STATE
  const [searchValue, setSearchValue] = React.useState('')
  const [selectedItemIds, setSelectedItemIds] = React.useState<string[]>([])
  const [showList, setShowList] = React.useState(false)
  // HANDLER
  const handleInputChanged = (value: string) => {
    if (value.length === 0) {
      setShowList(false)
    } else {
      setShowList(true)
    }
    onSearchChanged(value)
  }

  const handleInputClicked = () => {
    setShowList(true)
  }

  const handlerCollapsedSearchInputChanged = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { value } = e.currentTarget
    setSearchValue(value)
    if (onHandlerCollapsedSearch && value !== '') {
      setSearchValue(value)
      onHandlerCollapsedSearch(value)
    }
  }
  const handleItemSelected = (itemId: string) => {
    onItemSelected(itemId)
  }

  const validSearch = () => {
    /** ********************* */
    // Data layers GTM
    window.dataLayer.push({
      event: 'sz_conversion',
      sz_conversion_type: `Filtre des ${
        dataTest === 'smartInstitution' ? 'établissements' : 'organisations'
      }`,
    })
    /** ********************* */
    onSearchValidated(selectedItemIds)
    setShowList(false)
    setSearchValue('')
  }
  const listRef = useRef<HTMLDivElement>(null)
  /* eslint-disable @typescript-eslint/no-explicit-any */
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShowList(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
  useEffect(() => {
    setSelectedItemIds([])
    setSearchValue('')
    onSearchChanged('')
  }, [forceReset, resetSearchValue])

  useEffect(() => {
    const newSelectedItemIds = selectedItems.map((item) => item.id)
    setSelectedItemIds([...newSelectedItemIds])
  }, [selectedItems])

  useEffect(() => {
    setShowList(displaySelected)
  }, [])

  useEffect(() => {
    if (organizationSetted) {
      setShowList(true)
    }
  }, [organizationSetted])
  return (
    <>
      {isBandeau ? (
        <>
          {isCollapsedSearch ? (
            <SzInput
              value={searchValue}
              placeholder={t('organizationPerimeter.inputPlaceholder')}
              icon="search"
              onChange={handlerCollapsedSearchInputChanged}
            />
          ) : (
            <Input
              resetSearchValue={resetSearchValue}
              placeholder={
                isOrga
                  ? t('attachmentInstitution.placeholder.orgaPlaceHolder')
                  : t(
                      'attachmentInstitution.placeholder.placeholderNewSearchInstitution'
                    )
              }
              forceReset={forceReset}
              disabled={disabledSearch}
              onChange={handleInputChanged}
              onClick={handleInputClicked}
              dataTest={dataTest}
            />
          )}

          <StyledSmartListe showList={showList}>
            <div ref={listRef}>
              <Loading isLoading={isLoading}>
                <div className="search-with-list__scroll">
                  <div className="search-with-list__count">
                    {count} {countLabel}
                  </div>
                  {(isOrga && count > REACT_APP_BASE_API_WDH_MAX_RESULT) ||
                  (!isOrga && count > REACT_APP_FILTER_MAX_ORGANIZATION) ? (
                    <p className="search-with-list__tooMuch">
                      {isOrga ? (
                        <>{t('perimeterfilter.search.result.tooMuchOrga')}</>
                      ) : (
                        <>{t('perimeterfilter.search.result.tooMuch')}</>
                      )}
                    </p>
                  ) : (
                    <SmartList
                      items={items}
                      selectedItemIds={selectedItemIds}
                      displayCheckbox={displayCheckbox}
                      onItemSelected={handleItemSelected}
                      onItemUnSelected={onItemUnSelected}
                    />
                  )}
                </div>

                {(items && items.length > 0) ||
                selectedItems.length > 0 ||
                count > REACT_APP_FILTER_MAX_ORGANIZATION ? (
                  <div className="search-with-list__ok">
                    <Button
                      dataTest={dataTest?.concat('Btn')}
                      onClick={validSearch}
                    >
                      OK
                    </Button>
                  </div>
                ) : null}
              </Loading>
            </div>
          </StyledSmartListe>
        </>
      ) : (
        <div className="search-with-list font-medium">
          {displaySelected && (
            <SearchSelected
              placeholder={selectedPlaceholder}
              items={selectedItems}
              entityAbbreviation={t(
                'perimeterfilter.search.institutionAbbreviation'
              )}
              onUnselect={onItemUnSelected}
            />
          )}

          {isCollapsedSearch ? (
            <SzInput
              value={searchValue}
              placeholder={t('organizationPerimeter.inputPlaceholder')}
              icon="search"
              onChange={handlerCollapsedSearchInputChanged}
            />
          ) : (
            <Input
              resetSearchValue={resetSearchValue}
              placeholder={
                isOrga
                  ? t('attachmentInstitution.placeholder.search')
                  : t(
                      'attachmentInstitution.placeholder.placeholderSearchInstitution'
                    )
              }
              forceReset={forceReset}
              disabled={disabledSearch}
              onChange={handleInputChanged}
            />
          )}

          <Loading isLoading={isLoading}>
            <div className="search-with-list__scroll">
              <div className="search-with-list__count">
                {count} {countLabel}
              </div>
              {(isOrga && count > REACT_APP_BASE_API_WDH_MAX_RESULT) ||
              (!isOrga && count > REACT_APP_FILTER_MAX_ORGANIZATION) ? (
                <p className="search-with-list__tooMuch">
                  {isOrga ? (
                    <>{t('perimeterfilter.search.result.tooMuchOrga')}</>
                  ) : (
                    <>{t('perimeterfilter.search.result.tooMuch')}</>
                  )}
                </p>
              ) : (
                <SmartList
                  items={items}
                  selectedItemIds={selectedItemIds}
                  displayCheckbox={displayCheckbox}
                  onItemSelected={handleItemSelected}
                  onItemUnSelected={onItemUnSelected}
                />
              )}
            </div>

            {(items && items.length > 0) ||
            count > REACT_APP_FILTER_MAX_ORGANIZATION ? (
              <div className="search-with-list__ok">
                <Button
                  dataTest={dataTest?.concat('Btn')}
                  onClick={validSearch}
                >
                  OK
                </Button>
              </div>
            ) : null}
          </Loading>
        </div>
      )}
    </>
  )
}
SearchWithList.defaultProps = {
  items: [],
  displayCheckbox: true,
  selectedPlaceholder: '',
  forceReset: false,
  resetSearchValue: false,
  isCollapsedSearch: false,
  isOrga: false,
  onHandlerCollapsedSearch: undefined,
  disabledSearch: false,
  isBandeau: false,
  dataTest: '',
  organizationSetted: false,
}

export default SearchWithList
