/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import debounce from 'lodash.debounce'
import React, {
  FC,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import SearchInstitutionAdapter from '../adapter/SearchInstitutionAdapter'
import { searchInstitutionByTextAndOrganizationsIds } from '../services/institution-services'
import { ActionTypes } from '../store/actions'
import { AppContext } from '../store/context'
import InstitutionEntity from '../utils/InstitutionEntity'
import SmartSelect from './SmartSelect'
import SvgIcon from './SvgIcon'
import useElementSizeListener from '../utils/useElementSizeListener'
import { isCancel } from '../services/billing-services'
import { isSuperAdmin } from '../services/roles-services'
import SearchResultItem from '../utils/SearchResultItem'
import SelectedItems from './molecules/SelectedItems'

interface Props {
  disableGlobalState?: boolean
  onSearchValidate?: (institutions: InstitutionEntity[]) => void
  initalValues?: InstitutionEntity[]
  isInstitutionsAlreadySet?: boolean
  organizationSetted?: boolean
  forceReset?: boolean
  setForceReset?: React.Dispatch<React.SetStateAction<boolean>> | undefined
  forceUnfold?: boolean
  isCollapsedSearch?: boolean
  onHandlerCollapsedSearch?: (SearchValue: string) => void
  onInstitutionsSetted?: () => void
  selectedInstitutions?: InstitutionEntity[]
  displaySelected?: boolean
  disabledSearch?: boolean
  expandedMode?: boolean
  isBandeau?: boolean
}

const SmartSelectInstitution: FC<Props> = ({
  disableGlobalState,
  onSearchValidate,
  initalValues,
  isInstitutionsAlreadySet,
  organizationSetted,
  forceReset,
  setForceReset,
  forceUnfold,
  isCollapsedSearch,
  onHandlerCollapsedSearch,
  onInstitutionsSetted,
  selectedInstitutions,
  displaySelected,
  disabledSearch,
  expandedMode,
  isBandeau,
}: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const cookies = new Cookies()

  const {
    state: { user, institution, organization, impersonnation },
    dispatch,
  } = useContext(AppContext)

  // STATE
  const [entities, setEntities] = useState<InstitutionEntity[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [
    resetSearchValueAfterValidate,
    setResetSearchValueAfterValidate,
  ] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = React.useState<SearchResultItem[]>(
    []
  )
  const selectRef = useRef(null)
  const { width: elementSize } = useElementSizeListener(selectRef)

  const searchInstitutionItems = useMemo(() => {
    return SearchInstitutionAdapter(entities)
  }, [entities])

  const initialItems = initalValues
    ? SearchInstitutionAdapter(initalValues)
    : undefined
  const selectedInstitutionsItem = selectedInstitutions
    ? SearchInstitutionAdapter(selectedInstitutions)
    : []
  const callApiAsync = async (organizationIds: string[]) => {
    const maxTotalLimit = parseInt(
      `${process.env.REACT_APP_FILTER_MAX_ORGANIZATION}`,
      10
    )

    /* eslint-disable-next-line no-param-reassign */
    if (isInstitutionsAlreadySet) organizationIds = []
    try {
      setIsLoading(true)
      const {
        entities: resultEntities,
        totalItems: resultTotalItems,
      } = await searchInstitutionByTextAndOrganizationsIds(
        searchValue,
        organizationIds,
        history,
        maxTotalLimit,
        disableGlobalState,
        200,
        expandedMode
      )
      if (isCancel(entities)) {
        return
      }
      if (resultEntities) {
        // dispatch institutionPerimeter initial without filters organizations or institutions
        if (organizationIds.length === 0 && searchValue.length === 0) {
          dispatch({
            type: ActionTypes.SET_INSTITUTION_PERIMETER,
            payload: {
              institutionPerimeter: resultEntities,
            },
          })
        }
        setEntities(resultEntities)
        setTotalItems(resultTotalItems)
      }

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.debug(e)
    }
  }

  const handleInstitutionChanged = async (value: string) => {
    setSearchValue(value)
  }

  const handleInstitutionChangedDebounced = debounce(
    handleInstitutionChanged,
    1000
  )
  const HandlerCollapsedSearch = async (value: string) => {
    setSearchValue(value)
    if (onHandlerCollapsedSearch) onHandlerCollapsedSearch(value)
  }
  const HandlerCollapsedSearchDebounced = debounce(HandlerCollapsedSearch, 1000)

  const handleInstitutionSearchValidated = (selectedItemIds: any[]) => {
    const tmpInstIds: string[] = []
    institution.items.forEach((insti: InstitutionEntity) => {
      tmpInstIds.push(insti.id)
    })

    setResetSearchValueAfterValidate(true)
    const listIdInstitution: string[] = []

    if (selectedItemIds.length > 0) {
      dispatch({
        type: ActionTypes.SET_INSTITUTION_VALIDATED,
        payload: {
          institutionValidated: true,
        },
      })
    }

    if (!disableGlobalState)
      institution.items.map((item: InstitutionEntity) => {
        listIdInstitution.push(item.id)
      })

    const removeInstitution: string[] = []

    const whichOne =
      initalValues && initalValues.length > entities.length
        ? initalValues
        : entities
    whichOne.map((ent: InstitutionEntity) => {
      if (
        !selectedItemIds.includes(ent.id) &&
        listIdInstitution.includes(ent.id)
      ) {
        removeInstitution.push(ent.id)
      }
    })

    const fullResult: InstitutionEntity[] = []
    whichOne.map((item: InstitutionEntity) => {
      if (
        !listIdInstitution.includes(item.id) &&
        selectedItemIds.includes(item.id)
      ) {
        fullResult.push(item)
      }
    })

    const tmpResult = !disableGlobalState
      ? [...institution.items, ...fullResult]
      : fullResult

    const finalResult = tmpResult.filter(
      (item) => !removeInstitution.includes(item.id)
    )

    if (onInstitutionsSetted) {
      onInstitutionsSetted()
    }

    if (disableGlobalState) {
      if (onSearchValidate) onSearchValidate(finalResult)
    } else {
      dispatch({
        type: ActionTypes.SET_INSTITUTION_LIST,
        payload: {
          institutionList: [...finalResult],
        },
      })
      const institutionsList = JSON.stringify(
        finalResult.map((itm: InstitutionEntity) => itm.id)
      )
      // put institution in cookies
      if (!impersonnation.isImpersonnation) {
        if (institutionsList !== '[]') {
          cookies.set('chosenInstitution', institutionsList, {
            path: '/',
            secure: true,
          })
        } else {
          cookies.remove('chosenInstitution')
        }
      }
    }
  }

  // EFFECTS
  useEffect(() => {
    if (institution.items.length > 0) {
      setIsLoading(true)
      setIsLoading(false)
    } else if (initalValues) {
      setIsLoading(true)
      setEntities(initalValues)
      setTotalItems(initalValues.length)
      setIsLoading(false)
    } else {
      setIsLoading(true)
      setEntities([])
      setTotalItems(0)
      setIsLoading(false)
    }
  }, [institution, forceUnfold])

  useEffect(() => {
    setResetSearchValueAfterValidate(false)
    if (initalValues && initalValues.length > 0) {
      setIsLoading(true)
      setEntities(initalValues)
      setTotalItems(initalValues.length)
      setIsLoading(false)
    }
  }, [initalValues, forceUnfold])

  useEffect(() => {
    if (!organizationSetted) {
      setIsLoading(true)
      setSearchValue('')
      setEntities([])
      setTotalItems(0)
      setIsLoading(false)
    }
  }, [forceUnfold, organizationSetted])

  useEffect(() => {
    if (
      user.roleNames.length &&
      ((isSuperAdmin(user.roleNames) && searchValue.length) ||
        !isSuperAdmin(user.roleNames) ||
        organization.items.length > 0)
    ) {
      // empty array, means access to all organization
      let organizationIds: string[] = []
      organizationIds =
        organization.items.length > 0 ? organization.items.map((o) => o.id) : []

      if (!initalValues || initalValues.length === 0 || !organizationSetted) {
        callApiAsync(organizationIds)
      } else {
        const resultEntities = initalValues.filter(
          (item: InstitutionEntity) =>
            item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.numDebiteur
              .toLowerCase()
              .includes(searchValue.trim().toLowerCase()) ||
            item.address.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.villeCP.toLowerCase().includes(searchValue.toLowerCase())
        )
        setEntities(resultEntities)
        setTotalItems(resultEntities.length)
      }
    }
  }, [organization, searchValue, user])

  useEffect(() => {
    setResetSearchValueAfterValidate(true)
  }, [organization, user])

  useEffect(() => {
    setEntities([])
    setTotalItems(0)
  }, [forceReset])

  const handleItemsSelected = (items: any) => {
    // 👇️ take parameter passed from Child component
    setSelectedItems([...selectedItems, items])
  }
  const handleItemsUnselected = (item: any) => {
    setSelectedItems(selectedItems.filter((e) => e.id !== item))
  }
  const onItemUnselected = (toDeleteId: string) => {
    const newSelectedItems = selectedItems.filter(
      (item) => toDeleteId !== item.id
    )
    const institutionsList = JSON.stringify(
      newSelectedItems.map((itm: SearchResultItem) => itm.id)
    )
    cookies.set('chosenInstitution', institutionsList, {
      path: '/',
      secure: true,
    })
    handleInstitutionSearchValidated(JSON.parse(institutionsList))
    setSelectedItems([...newSelectedItems])
  }
  const selectedPlaceholder =
    selectedInstitutionsItem.length > 1
      ? `${selectedInstitutionsItem.length} ${t(
          'perimeterfilter.select.institutions.multi'
        )}`
      : `${selectedInstitutionsItem.length} ${t(
          'perimeterfilter.select.institutions.single'
        )}`
  useEffect(() => {
    setSelectedItems(selectedInstitutionsItem)
  }, [institution])

  return (
    <div className="perimeter-filter__select" ref={selectRef}>
      <SmartSelect
        isCollapsedSearch={isCollapsedSearch}
        onHandlerCollapsedSearch={HandlerCollapsedSearchDebounced}
        parentWidth={elementSize}
        placeholder={
          <>
            <SvgIcon
              name="building"
              className="perimeter-filter__select-icon"
            />
            <span>{t('perimeterfilter.placeholder.select')}</span>
          </>
        }
        selectedPlaceholderSingle={t(
          'perimeterfilter.select.institutions.single'
        )}
        selectedPlaceholderMulti={t(
          'perimeterfilter.select.institutions.multi'
        )}
        countLabel={
          searchInstitutionItems.length <= 1
            ? t('perimeterfilter.search.resultInstitution.single')
            : t('perimeterfilter.search.resultInstitution.multi')
        }
        count={totalItems}
        searchResults={searchInstitutionItems}
        displaySelected={displaySelected}
        resetSearchValue={resetSearchValueAfterValidate}
        isLoading={isLoading}
        forceReset={forceReset}
        forceUnfold={forceUnfold}
        onSearchChanged={handleInstitutionChangedDebounced}
        onSearchValidated={handleInstitutionSearchValidated}
        initialValues={initialItems}
        selectedItemsProp={selectedInstitutionsItem}
        disabledSearch={disabledSearch}
        isBandeau={isBandeau}
        dataTest="smartInstitution"
        handleItemsSelected={handleItemsSelected}
        handleItemsUnselected={handleItemsUnselected}
        itemsSelected={selectedItems}
        organizationSetted={organizationSetted}
        isOrga={false}
      />
      {isBandeau && selectedInstitutionsItem && selectedItems.length > 0 && (
        <SelectedItems
          placeholder={selectedPlaceholder}
          items={selectedItems}
          entityAbbreviation={t(
            'perimeterfilter.search.institutionAbbreviation'
          )}
          onUnselect={onItemUnselected}
        />
      )}
    </div>
  )
}
SmartSelectInstitution.defaultProps = {
  disableGlobalState: false,
  onSearchValidate: () => ({}),
  initalValues: undefined,
  isInstitutionsAlreadySet: false,
  organizationSetted: false,
  forceReset: false,
  setForceReset: undefined,
  forceUnfold: false,
  isCollapsedSearch: false,
  onHandlerCollapsedSearch: undefined,
  onInstitutionsSetted: undefined,
  selectedInstitutions: undefined,
  displaySelected: true,
  disabledSearch: false,
  expandedMode: false,
  isBandeau: false,
}

export default SmartSelectInstitution
