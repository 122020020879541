/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
import OrganizationEntity from './OrganizationEntity'

/**
 * - if elements in filterList are absents in list, they are added
 * - if elements in filterList are present in list, they are removed
 */
export function filterArray(list: string[], filterList: string[]): string[] {
  let finalList = [...list]
  filterList.forEach((item) => {
    if (finalList.includes(item)) {
      finalList = finalList.filter((id) => id !== item)
    } else {
      finalList.push(item)
    }
  })
  return [...finalList]
}

export function distinctArray(array: string[]) {
  let index = 0
  const newArray: string[] = []
  while (index < array.length) {
    if (!newArray.includes(array[index])) {
      newArray.push(array[index])
    }
    index += 1
  }
  return newArray
}

export function cutInterval(str: string) {
  const res: string[] = []
  const index = str.indexOf(' ', str.indexOf(' ') + 1)

  const firstChunk = str.slice(0, index)
  const secondChunk = str.slice(index + 1)
  res.push(firstChunk)
  res.push(secondChunk)
  return res
}

export function clearSameIds(orga: OrganizationEntity[]): OrganizationEntity[] {
  const clearOrga: OrganizationEntity[] = []
  const uniqueIds: string[] = []

  orga.forEach((org: OrganizationEntity) => {
    if (!uniqueIds.includes(org.id)) {
      clearOrga.push(org)
      uniqueIds.push(org.id)
    }
  })

  return clearOrga
}
export function duplicateChecker(arr: any, target: any) {
  return target.every((v: any) =>
    arr
      .map((item: { id: any }) => {
        return item.id
      })
      .includes(v.id)
  )
}

export function equalsArray(arr1: string[], arr2: string[]) {
  if (arr1 == null || arr2 == null || arr1.length !== arr2.length) return false

  let result = true
  arr1.forEach((elem: string) => {
    if (!arr2.includes(elem)) {
      result = false
    }
  })

  arr2.forEach((elem: string) => {
    if (!arr1.includes(elem)) {
      result = false
    }
  })

  return result
}

export function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index
}

export function uniqueById(arr: any) {
  return arr.reduce((accumulator: any[], current: { id: any }) => {
    if (!accumulator.some((x) => x.id === current.id)) {
      accumulator.push(current)
    }
    return accumulator
  }, [])
}

export function getArrayIds(arr: any) {
  return arr.map((element: { id: any }) => element.id)
}

export function groupBy(list: any[], keyGetter: any) {
  return list.reduce((accumulator, item) => {
    const key = keyGetter(item)
    if (!accumulator[key]) {
      accumulator[key] = []
    }
    accumulator[key].push(item)
    return accumulator
  }, {})
}

export function sortBy(list: any[], iteratee: string) {
  return list.sort((a, b) => a[iteratee].localeCompare(b[iteratee]))
}

export function getRowsByIdsSelected(
  tableEvolutionEntity: any[],
  ids: string[]
) {
  return tableEvolutionEntity
    .filter(
      (value, index, array) =>
        array.findIndex((xt) => xt.id === value.id) === index
    )
    .filter((data) => ids.includes(data.id.toString()))
}

export function arrayContainsArray(
  arr1: (string | number)[],
  arr2: (string | number)[]
): boolean {
  if (!arr1.length || !arr2.length) return false
  return arr1.every((arrayElement) => arr2.includes(arrayElement))
}

export function getIdsFromGlobalArrayIds(idsRows: string[], index: number) {
  return idsRows
    .map((item) => {
      return item.split(' ')[index]
    })
    .filter((element, pos, array) => array.indexOf(element) === pos)
}
