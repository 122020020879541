/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import ROLES from '../utils/ROLES'
import { getEnumKeyByValue } from '../utils/ts-utils'
import AllRolesEntity, { SelectedRoleEntity } from '../utils/AllRolesEntity'
import ROLES_ACCESS from '../utils/ROLES_ACCESS'

function formatDataRoles(data: any) {
  const result = []
  let tmp
  for (let index = 0; index < data.length; index += 1) {
    tmp = {
      id: '',
      name: '',
    }
    tmp.id = data[index]['@id']
    tmp.name = data[index].name
    result.push(tmp)
  }
  return result
}

export async function getAllRoles(): Promise<AllRolesEntity[]> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/roles`
  if (axios.defaults) {
    const accessToken = localStorage.getItem('access_token')
    axios.defaults.headers.common = {
      Authorization: `Bearer ${accessToken}`,
    }
  }

  let allRoles
  try {
    const resp = await axios({
      method: 'GET',
      url,
    })
    allRoles = formatDataRoles(resp?.data['hydra:member'])
  } catch (err) {
    throw new Error(`Erreur lors de l'obtention des rôles`)
  }
  return allRoles
}

export async function getRoleName(roleUri: string): Promise<string> {
  const url = `${process.env.REACT_APP_BASE_API_URL}${roleUri}`

  let roleName

  try {
    const resp = await axios({
      method: 'GET',
      url,
    })
    roleName = resp?.data?.name
  } catch (err) {
    throw new Error(`Erreur '${roleUri}'`)
  }

  return roleName
}

export async function getRoleNames(
  roleUris: Array<string>
): Promise<Array<string>> {
  const result: Array<string> = []

  await Promise.all(
    roleUris.map(async (roleUri) => {
      const roleName = await getRoleName(roleUri)
      result.push(roleName)
    })
  )

  return result
}

export function hasAccessTo(
  businessRoles: Array<number>,
  currentRoles: Array<number>
) {
  return businessRoles.find((businessRole) =>
    currentRoles.find((currentRole) => currentRole === businessRole)
  )
}

export function hasOnlyAccessToActivity(
  businessRoles: Array<number>,
  currentRoles: Array<number>
) {
  if (currentRoles.length === 1) {
    return businessRoles.find((businessRole) =>
      currentRoles.find((currentRole) => currentRole === businessRole)
    )
  }
  return false
}

export function hasAdminActivityAccessTo(
  businessRoles: Array<number>,
  currentRoles: Array<number>
) {
  return currentRoles.every((currentRole) =>
    businessRoles.includes(currentRole)
  )
}

export function roleNamesToNumber(roleNames: Array<string>): Array<number> {
  return roleNames.map((roleName: string) =>
    parseInt(getEnumKeyByValue(ROLES, roleName), 10)
  )
}

export function getSelectedRolesName(selectedRoles: {
  [key: string]: SelectedRoleEntity
}): string[] {
  const selectedNames = Object.values(selectedRoles)
    .filter((role) => role.selected)
    .map((role) => role.name)
    .sort()

  return selectedNames.length > 0 ? selectedNames : ['']
}

export function getRoleUrisFromRoleNames(
  roleNames: string[],
  allRoles: AllRolesEntity[]
) {
  return allRoles
    .filter(({ name }) => roleNames.includes(name))
    .map(({ id }) => id)
}

// eslint-disable-next-line
export function resetRolesTab(roles: any) {
  const tmp = roles
  for (const property in tmp) {
    if (tmp[property].selected) {
      tmp[property].selected = !tmp[property].selected
    }
  }
  return tmp
}

export function getHigherRole(roleNames: string[]) {
  if (roleNames.includes('ROLE_INT_SUPER_ADMIN')) {
    return 'admin_totale'
  }
  if (
    roleNames.includes('ROLE_INT_ADMIN_INTERNAL') &&
    roleNames.includes('ROLE_INT_ADMIN_CLIENT')
  ) {
    return 'admin_client_intern'
  }
  if (roleNames.includes('ROLE_INT_ADMIN_INTERNAL')) {
    return 'admin_intern'
  }
  if (roleNames.includes('ROLE_INT_ADMIN_CLIENT')) {
    return 'admin_client'
  }
  if (roleNames.includes('ROLE_CLI_ADMIN')) {
    return 'admin_delegue'
  }
  if (roleNames.includes('ROLE_INT_SUPPORT_N1')) {
    return 'admin_support_n1'
  }
  return ''
}

export function isSuperAdmin(roleNames: string[]) {
  return roleNames.includes('ROLE_INT_SUPER_ADMIN')
}

const getRoleID = (name: string, items: AllRolesEntity[]) => {
  for (let index = 0; index < items.length; index += 1) {
    if (name === items[index].name) {
      return items[index].id
    }
  }
  return ''
}

// eslint-disable-next-line
export function fillSelectedRoles(roles: any, items: AllRolesEntity[]) {
  const rolesID = []
  for (const property in roles) {
    if (roles[property].selected) {
      rolesID.push(getRoleID(roles[property].name, items))
    }
  }
  return rolesID
}

export function canCreateOrganization(roleNames: string[]): boolean {
  return roleNames.some((role) =>
    [
      'ROLE_INT_SUPER_ADMIN',
      'ROLE_CLI_ADMIN',
      'ROLE_INT_ADMIN_CLIENT',
      'ROLE_INT_ADMIN_INTERNAL',
    ].includes(role)
  )
}

export function canManageClientFamily(roleNames: string[]): boolean {
  return roleNames.some((role) =>
    ['ROLE_CLI_ADMIN', 'ROLE_INT_ADMIN_CLIENT'].includes(role)
  )
}

// check if admin manage intern
export function canInternManageClientFamily(roleNames: string[]): boolean {
  return roleNames.some((role) =>
    [
      'ROLE_INT_ADMIN_CLIENT',
      'ROLE_INT_SUPER_ADMIN',
      'ROLE_INT_SUPPORT_N1',
    ].includes(role)
  )
}

// check if admin
export function isAdmin(roleNames: string[]): boolean {
  return roleNames.some((role) =>
    [
      'ROLE_INT_SUPER_ADMIN',
      'ROLE_CLI_ADMIN',
      'ROLE_INT_ADMIN_CLIENT',
      'ROLE_INT_ADMIN_INTERNAL',
      'ROLE_INT_SUPPORT_N1',
    ].includes(role)
  )
}

// check if intern user can edit external user
export function canEditExternUsers(
  roleNames: string[],
  userToEditType = ''
): boolean {
  const internalRoles = [
    'ROLE_INT_ADMIN_INTERNAL',
    'ROLE_INT_BILLING',
    'ROLE_INT_ACTIVITIES',
  ]

  // If intern user doesn't have ROLE_INT_ADMIN_CLIENT, so he can't edit an external user
  if (
    internalRoles.some((role) => roleNames.includes(role)) &&
    !roleNames.includes('ROLE_INT_ADMIN_CLIENT') &&
    userToEditType === 'external'
  ) {
    return false
  }

  return isAdmin(roleNames)
}

export function isActivity(roleNames: string[]): boolean {
  return roleNames.some((role) =>
    [
      'ROLE_INT_SUPER_ADMIN',
      'ROLE_INT_ACTIVITIES',
      'ROLE_CLI_ACTIVITIES',
    ].includes(role)
  )
}

export function isBilling(roleNames: string[]): boolean {
  return roleNames.some((role) =>
    ['ROLE_INT_SUPER_ADMIN', 'ROLE_INT_BILLING', 'ROLE_CLI_BILLING'].includes(
      role
    )
  )
}
// check if admin délégué
export function isManagingAdmin(roleNames: string[]): boolean {
  return (
    roleNames.includes('ROLE_INT_ACTIVITIES') ||
    roleNames.includes('ROLE_INT_BILLING') ||
    roleNames.includes('ROLE_INT_ADMIN_CLIENT') ||
    roleNames.includes('ROLE_INT_ADMIN_INTERNAL') ||
    roleNames.includes('ROLE_INT_SUPER_ADMIN') ||
    roleNames.includes('ROLE_INT_SUPPORT_N1')
  )
}
// check if the user has "usermanagement" page as landing page
export function userAdminLandingPage(roleNames: string[]): boolean {
  // tarnslate roles to numbers
  const currentRoles = roleNamesToNumber(roleNames)
  if (
    hasAccessTo(ROLES_ACCESS.firstPageAdminUsersRoles, currentRoles) ||
    (currentRoles.length === 1 && currentRoles.includes(ROLES.ROLE_CLI_ADMIN))
  )
    return true
  // doesnt satisfy required rules
  return false
}

//  can manage document
export function canManageDocuement(
  roleNames: string[],
  accessDocs = false
): boolean {
  return (
    roleNames.includes('ROLE_INT_SUPER_ADMIN') ||
    (roleNames.some((role) =>
      [
        'ROLE_INT_ADMIN_CLIENT',
        'ROLE_INT_ADMIN_INTERNAL',
        'ROLE_INT_ACTIVITIES',
        'ROLE_INT_BILLING',
      ].includes(role)
    ) &&
      accessDocs)
  )
}

// Admin but can't edit (ex: support role)
export function canOnlyRead(roleNames: string[]): boolean {
  return roleNames.includes('ROLE_INT_SUPPORT_N1')
}

export function getSupportNLabels(roleName: string, fullCharacters?: boolean) {
  if (roleName === 'ROLE_INT_SUPPORT_N1') {
    return fullCharacters ? 'Support N1' : 'N1'
  }

  return ''
}
