import React from 'react'
import { Route, useLocation } from 'react-router-dom'
import {
  WITH_PERIMETER_FILTER,
  PATHS_WITHOUT_PERIMETER_FILTER,
} from '../utils/ROUTES'

import Menu from './Menu'
import PerimeterFilter from './PerimeterFilter'

const HeaderAuthenticated: React.FC = () => {
  const pathName = useLocation().pathname

  let result = false
  PATHS_WITHOUT_PERIMETER_FILTER.forEach((elem) => {
    if (elem === pathName || pathName.startsWith(elem)) {
      result = true
    }
  })

  return (
    <div
      className={`sticky screen__fixed ${
        result ? 'sticky__without-perimeter' : ''
      }`}
    >
      <Menu />
      <Route path={WITH_PERIMETER_FILTER} exact>
        <PerimeterFilter />
      </Route>
    </div>
  )
}

export default HeaderAuthenticated
