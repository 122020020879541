/* eslint-disable camelcase */
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import axios from 'axios'
import jwt_decode from 'jwt-decode'

import {
  DecodedToken,
  dispatchUserInformations,
} from '../services/user-services'
import { AppContext } from '../store/context'
import ROUTES, { EXCLUDED_ROUTES } from '../utils/ROUTES'
import Color from '../utils/COLORS'
import { searchOrganizationByIds } from '../services/organization-services'
import { ActionTypes } from '../store/actions'
import { getEntreprisesByIds } from '../services/institution-services'
import { logout } from '../services/connexion-services'

const AutoRefreshToken: React.FC = () => {
  const history = useHistory()
  const location = useLocation()

  const { state, dispatch, updateRoleName } = React.useContext(AppContext)

  // Test keep connection -> refresh_token cookie
  React.useEffect(() => {
    // EXCLUDE PAGES
    // Preparing the pathname
    const pathname =
      location.pathname.slice(-1) === '/'
        ? location.pathname.slice(0, -1)
        : location.pathname
    // test on the pathname
    if (EXCLUDED_ROUTES.indexOf(pathname) > -1) {
      return
    }

    async function refreshStore(
      accessToken: string,
      organizationsList: string[],
      institutionsList: string[]
    ) {
      try {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${accessToken}`,
        }
        const decodedToken: DecodedToken = jwt_decode(accessToken)
        const userData = {
          username: decodedToken.username,
          userId: decodedToken.user_id,
          isInternal: Boolean(decodedToken.isInternal),
          organizations: decodedToken.organizations.map((org) => org.id),
          roleNames: decodedToken.roles.map((e) => e.name),
          firstname: decodedToken.first_name,
          accessDemands: Boolean(decodedToken.access_demands),
          accessDocs: Boolean(decodedToken.access_docs),
          idContactWdh: decodedToken.id_contact_wdh,
        }

        await dispatchUserInformations(
          { ...userData, backgroundColor: Color.GRAY_0 },
          dispatch,
          state
        )

        await updateRoleName(userData.roleNames)
      } catch (e) {
        logout()
        history.push(ROUTES.CheckEmail)
      }

      try {
        if (organizationsList) {
          const { entities } = await searchOrganizationByIds(
            organizationsList,
            history
          )
          const filterdEntities = entities.filter((entity) => {
            return organizationsList.includes(entity.id)
          })
          if (filterdEntities)
            dispatch({
              type: ActionTypes.SET_ORGANIZATION_LIST,
              payload: {
                organizationList: filterdEntities,
              },
            })
        }

        if (institutionsList) {
          const { entities } = await getEntreprisesByIds(
            institutionsList,
            history,
            true
          )

          if (entities)
            dispatch({
              type: ActionTypes.SET_INSTITUTION_LIST,
              payload: {
                institutionList: entities,
              },
            })
        }
      } catch (e) {
        console.debug('error', e.message)
      }
    }

    try {
      const cookies = new Cookies()
      const refreshToken = localStorage.getItem('refresh_token')
      const accessToken = localStorage.getItem('access_token')
      const institutionsList = cookies.get('chosenInstitution')
      const organizationsList = cookies.get('chosenOrganization')
      if (!refreshToken || !accessToken) {
        if (location.pathname !== ROUTES.MaintenanceError) {
          localStorage.setItem(
            'redirectTo',
            `${location.pathname}${location.search}`
          )
          history.push(ROUTES.CheckEmail)
          return
        }
      }

      if (state.user.userId === '' && refreshToken && accessToken) {
        refreshStore(accessToken, organizationsList, institutionsList)
      }
    } catch (e) {
      console.log(e)
    }
  }, [state.user])

  return null
}

export default AutoRefreshToken
