import React from 'react'

import { ReactComponent as BonhommeIcon } from '../assets/icons/user-profile-v1.svg'
import { ReactComponent as MenuIcon } from '../assets/icons/menu.svg'
import { ReactComponent as ExpandLessIcon } from '../assets/icons/expand-less.svg'
import { ReactComponent as ExpandMoreIcon } from '../assets/icons/expand-more.svg'
import { ReactComponent as AssesstmentIcon } from '../assets/icons/assessment.svg'
import { ReactComponent as ReceiptIcon } from '../assets/icons/receipt.svg'
import { ReactComponent as FolderIcon } from '../assets/icons/folder.svg'
import { ReactComponent as ExchangeIcon } from '../assets/icons/exchange.svg'
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg'
import { ReactComponent as HouseIcon } from '../assets/icons/house-3.svg'
import { ReactComponent as FactureIcon } from '../assets/icons/facture.svg'
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg'
import { ReactComponent as DocumentIcon } from '../assets/icons/document.svg'
import { ReactComponent as BuildingIcon } from '../assets/icons/building.svg'
import { ReactComponent as MapIcon } from '../assets/icons/map.svg'
import { ReactComponent as HierarchyIcon } from '../assets/icons/hierarchy.svg'
import { ReactComponent as SuccessIcon } from '../assets/icons/success.svg'
import { ReactComponent as ArrowUpIcon } from '../assets/icons/arrow-up.svg'
import { ReactComponent as ArrowDownIcon } from '../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowLeftIcon } from '../assets/icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../assets/icons/arrow-right-1.svg'
import { ReactComponent as ArrowRightShortIcon } from '../assets/icons/arrow-right-short.svg'
import { ReactComponent as FilterIcon } from '../assets/icons/filter.svg'
import { ReactComponent as PlusIcon } from '../assets/icons/plus.svg'
import { ReactComponent as MinusIcon } from '../assets/icons/minus.svg'
import { ReactComponent as EyeIcon } from '../assets/icons/eye.svg'
import { ReactComponent as EyeFullIcon } from '../assets/icons/eye-full.svg'
import { ReactComponent as SendEmailIcon } from '../assets/icons/send-email.svg'
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg'
import { ReactComponent as CashPaymentBillIcon } from '../assets/icons/cash-payment-bill.svg'
import { ReactComponent as DownloadBottomIcon } from '../assets/icons/download-bottom.svg'
import { ReactComponent as FloppyDiskIcon } from '../assets/icons/floppy-disk.svg'
import { ReactComponent as CoordonneesIcon } from '../assets/icons/coordonnees.svg'
import { ReactComponent as BinIcon } from '../assets/icons/bin.svg'
import { ReactComponent as ReloadIcon } from '../assets/icons/diagram-round.svg'
import { ReactComponent as SynchronizeIcon } from '../assets/icons/synchronize-arrow-2.svg'
import { ReactComponent as GridIcon } from '../assets/icons/grid.svg'
import { ReactComponent as ListIcon } from '../assets/icons/list.svg'
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg'
import { ReactComponent as CircleCheckIcon } from '../assets/icons/check-circle-1.svg'
import { ReactComponent as AddSquareIcon } from '../assets/icons/add-square.svg'
import { ReactComponent as AddCircleIcon } from '../assets/icons/add-circle.svg'
import { ReactComponent as AddCircleFillIcon } from '../assets/icons/add-circle-fill.svg'
import { ReactComponent as SubstractSquareIcon } from '../assets/icons/substract-square.svg'
import { ReactComponent as UploadFile } from '../assets/icons/upload-file.svg'
import { ReactComponent as IconRight } from '../assets/icons/icon-right.svg'
import { ReactComponent as StepAfter } from '../assets/icons/step-after.svg'
import { ReactComponent as StepBefore } from '../assets/icons/step-before.svg'
import { ReactComponent as IndicatorArrow } from '../assets/icons/indicator-arrow.svg'
import { ReactComponent as InfoCircleIcon } from '../assets/icons/info-circle.svg'
import { ReactComponent as NavigationRightCircleIcon } from '../assets/icons/navigation-right-circle.svg'
import { ReactComponent as PencilWriteIcon } from '../assets/icons/pencil-write.svg'
import { ReactComponent as Users } from '../assets/icons/users.svg'
import { ReactComponent as PencilWriteBold } from '../assets/icons/pencil-write-bold.svg'
import { ReactComponent as PerformanceIncrease } from '../assets/icons/performance-increase.svg'
import { ReactComponent as FileUpload } from '../assets/icons/file-upload.svg'
import { ReactComponent as IndicEnviIcon } from '../assets/icons/picto_mesindicateurs_environnementaux.svg'
import { ReactComponent as CO2Icon } from '../assets/icons/picto_co2.svg'
import { ReactComponent as CO2BoldIcon } from '../assets/icons/picto_co2_bold.svg'
import { ReactComponent as PlaneIcon } from '../assets/icons/picto_avions.svg'
import { ReactComponent as CarIcon } from '../assets/icons/picto_voiture.svg'
import { ReactComponent as DiplomaIcon } from '../assets/icons/picto_diplome.svg'
import { ReactComponent as MealIcon } from '../assets/icons/picto_couverts.svg'
import { ReactComponent as CommonFileTextIcon } from '../assets/icons/common-file-text.svg'

interface Props {
  name: string
  className?: string
}

const SvgIcon: React.FC<Props> = ({ name, className }: Props) => {
  switch (name) {
    case 'bonhomme':
      return <BonhommeIcon className={className} />
    case 'indic-env-icon':
      return <IndicEnviIcon className={className} />
    case 'co2-icon':
      return <CO2Icon className={className} />
    case 'co2-bold-icon':
      return <CO2BoldIcon className={className} />
    case 'plane-icon':
      return <PlaneIcon className={className} />
    case 'car-icon':
      return <CarIcon className={className} />
    case 'meal-icon':
      return <MealIcon className={className} />
    case 'diploma-icon':
      return <DiplomaIcon className={className} />
    case 'menu':
      return <MenuIcon className={className} />
    case 'expand-less':
      return <ExpandLessIcon className={className} />
    case 'expand-more':
      return <ExpandMoreIcon className={className} />
    case 'assesstment':
      return <AssesstmentIcon className={className} />
    case 'receipt':
      return <ReceiptIcon className={className} />
    case 'folder':
      return <FolderIcon className={className} />
    case 'exchange':
      return <ExchangeIcon className={className} />
    case 'home':
      return <HomeIcon className={className} />
    case 'house':
      return <HouseIcon className={className} />
    case 'facture':
      return <FactureIcon className={className} />
    case 'download':
      return <DownloadIcon className={className} />
    case 'document':
      return <DocumentIcon className={className} />
    case 'building':
      return <BuildingIcon className={className} />
    case 'map':
      return <MapIcon className={className} />
    case 'hierarchy':
      return <HierarchyIcon className={className} />
    case 'success':
      return <SuccessIcon className={className} />
    case 'arrowUp':
      return <ArrowUpIcon className={className} />
    case 'arrowDown':
      return <ArrowDownIcon className={className} />
    case 'arrowLeft':
      return <ArrowLeftIcon className={className} />
    case 'arrowRight':
      return <ArrowRightIcon className={className} />
    case 'arrowRightShort':
      return <ArrowRightShortIcon className={className} />
    case 'filter':
      return <FilterIcon className={className} />
    case 'plus':
      return <PlusIcon className={className} />
    case 'minus':
      return <MinusIcon className={className} />
    case 'eye':
      return <EyeIcon className={className} />
    case 'eye-full':
      return <EyeFullIcon className={className} />
    case 'send-email':
      return <SendEmailIcon className={className} />
    case 'close':
      return <CloseIcon className={className} />
    case 'cash-payment-bill':
      return <CashPaymentBillIcon className={className} />
    case 'download-bottom':
      return <DownloadBottomIcon className={className} />
    case 'floppy-disk':
      return <FloppyDiskIcon className={className} />
    case 'coordonnees':
      return <CoordonneesIcon className={className} />
    case 'bin':
      return <BinIcon className={className} />
    case 'reload':
      return <ReloadIcon className={className} />
    case 'synchronize':
      return <SynchronizeIcon className={className} />
    case 'grid':
      return <GridIcon className={className} />
    case 'list':
      return <ListIcon className={className} />
    case 'eye_full':
      return <EyeFullIcon className={className} />
    case 'check':
      return <CheckIcon className={className} />
    case 'circleCheck':
      return <CircleCheckIcon className={className} />
    case 'addSquare':
      return <AddSquareIcon className={className} />
    case 'addCircle':
      return <AddCircleIcon className={className} />
    case 'addCircleFill':
      return <AddCircleFillIcon className={className} />
    case 'substractSquare':
      return <SubstractSquareIcon className={className} />
    case 'uploadFile':
      return <UploadFile className={className} />
    case 'iconRight':
      return <IconRight className={className} />
    case 'stepAfter':
      return <StepAfter className={className} />
    case 'stepBefore':
      return <StepBefore className={className} />
    case 'indicator-arrow':
      return <IndicatorArrow className={className} />
    case 'infoCircle':
      return <InfoCircleIcon className={className} />
    case 'navigationRightCircle':
      return <NavigationRightCircleIcon className={className} />
    case 'pencilWrite':
      return <PencilWriteIcon className={className} />
    case 'users':
      return <Users className={className} />
    case 'pencilWriteBold':
      return <PencilWriteBold className={className} />
    case 'performance-increase':
      return <PerformanceIncrease className={className} />
    case 'file-upload':
      return <FileUpload className={className} />
    case 'common-file-text':
      return <CommonFileTextIcon className={className} />
    default:
      throw Error
  }
}

SvgIcon.defaultProps = {
  className: '',
}

export default SvgIcon
